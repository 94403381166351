import BattlecardsEditorDnDBuilder from './_battlecards_editor_dndbuilder';
import BattlecardPreviewProxy from './_battlecard_preview_proxy';
import BattlecardView from './_battlecard_view';
import BattlecardTryComposer from './_battlecard_try_composer';
import BattlecardsEditorLayouts from './_battlecards_editor_layouts/_battlecards_editor_layouts';
import DraggableItem from './_draggable_item';
import Dropdown from './_dropdown';
import Icon from './_icon';

import {dig, isValidId} from '../modules/utils';
import {pluralize} from '../modules/text_utils';
import {alertMessageForPublishedBattlecard, battlecardReviewedAlert, battlecardReviewedAlertCardErrorMessage} from '../modules/battlecard_utils';
import {uiDefaultTypes, uiViewports} from '../modules/constants/ui';
import {builderViews} from '../modules/constants/battlecards';
import ViewAsDropdown from './_view_as_dropdown';

import moment from 'moment';
import classNames from 'classnames';
import {withRouter} from 'react-router-dom';
import {userCanCurate} from '../modules/roles_utils';

const BattlecardDraggable = DraggableItem('BC-DD-GROUP');

class BattlecardsEditor extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    appData: PropTypes.object.isRequired
  };

  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    newBattlecardMode: PropTypes.bool,
    cardTitles: PropTypes.arrayOf(PropTypes.object),
    allBattlecards: PropTypes.arrayOf(PropTypes.object),
    newBattlecardSaveObj: PropTypes.object,
    onBattlecardTileClicked: PropTypes.func,
    onViewAs: PropTypes.func,
    onDidUpdateBattlecardCards: PropTypes.func,
    boards: PropTypes.arrayOf(PropTypes.object)
  };

  static defaultProps = {
    history: {},
    location: {},
    match: {},
    newBattlecardMode: false,
    cardTitles: [],
    allBattlecards: [],
    newBattlecardSaveObj: {},
    onBattlecardTileClicked() {},
    onViewAs() {},
    onDidUpdateBattlecardCards() {},
    boards: []
  };

  state = {
    battlecardBuilderViewport: uiViewports.DESKTOP,
    view: builderViews.CURRENT_BATTLECARD,
    renameModeBattlecardId: null,
    showAddBattlecardUI: false,
    showCurrentBattlecardPreview: false,
    allBattlecards: []
  };

  componentDidMount() {
    console.log('BattlecardsEditor.componentDidMount: props: %o', this.props);

    const {newBattlecardMode, allBattlecards} = this.props;

    this.setState({
      allBattlecards,
      showAddBattlecardUI: newBattlecardMode,
      view: this._getBattlecardId() ? builderViews.CURRENT_BATTLECARD : builderViews.MANAGE_ALL
    });

    if(_.isEmpty(allBattlecards)) {
      // set default view state to "manage all" in case of a brand new profile w/no battlecards
      this.handleManageAllClick();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {allBattlecards, showAddBattlecardUI, view} = this.state;
    const {allBattlecards: nextAllBattlecards, newBattlecardMode, location: {state: locationState}} = nextProps;
    let source = null;

    if(locationState) {
      ({source} = locationState);
    }

    if(nextAllBattlecards && (nextAllBattlecards !== allBattlecards)) {
      this.setState({allBattlecards: nextAllBattlecards});
    }
    else if((
      ((source === uiDefaultTypes.TOC) && (view !== builderViews.CURRENT_BATTLECARD)) || this._getBattlecardId(nextProps)
    )) {
      // handle switching between battlecards in ToC when in the manage battlecards view
      this.setState({view: builderViews.CURRENT_BATTLECARD});
    }

    if(newBattlecardMode && (!this.props.newBattlecardMode || !showAddBattlecardUI)) {
      this.setState({
        showAddBattlecardUI: true,
        view: builderViews.MANAGE_ALL
      });
    }
  }

  _addBattlecardInputRef = null;

  _preventDefault = e => e && e.preventDefault();

  _setAddBattlecardInputRef = node => this._addBattlecardInputRef = node;

  _getBattlecardId = (props = this.props) => {
    const {location: {state: locationState}, match: {params = {}}} = props;

    let battlecardId = parseInt(params.battlecardId, 10) || null;

    if(locationState && !battlecardId) {
      ({battlecardId} = locationState);
    }

    return battlecardId;
  };

  _findBattlecard = (battlecardId = 0) => {
    if(!isValidId(battlecardId)) {
      return null;
    }

    return this.state.allBattlecards.find(b => b.id === battlecardId);
  };

  _getBattlecardEditPath = (battlecard = null, useBaseEditPath = false) => {
    if(_.isEmpty(battlecard)) {
      return;
    }

    const battlecardIdSlug = useBaseEditPath ? '' : `/${battlecard.id}`;

    return `/profile/${battlecard.profile.id}/battlecard/edit${battlecardIdSlug}`;
  };

  handleBattlecardReorder = (item, target) => {
    if(item.droppableCardIndex === target.droppableCardIndex) {
      return;
    }

    const allBattlecards = (this.state.allBattlecards && this.state.allBattlecards.slice()) || [];
    const moveIt = (x, from, to) => {
      x.splice((to < 0 ? x.length + to : to), 0, x.splice(from, 1)[0]);
    };

    moveIt(allBattlecards, item.droppableCardIndex, target.droppableCardIndex);

    this.refs.bcbuilder.classList.add('bcbuilder-loading');

    allBattlecards.forEach((battlecard, idx) => {
      if(!_.isEmpty(battlecard)) {
        const battlecardOptions = {
          id: battlecard.id,
          profileId: battlecard.profile.id,
          viewOrder: parseFloat(idx)
        };

        const callback = (idx === allBattlecards.length - 1) ? () => this.refs.bcbuilder.classList.remove('bcbuilder-loading') : null;

        this.context.api.battlecardCreateOrUpdate(battlecardOptions, callback);
      }
    });
  };

  handleBattlecardSelectClick = (currentBattlecard = null) => this.setState({view: builderViews.CURRENT_BATTLECARD}, () =>
    this.props.history.push(this._getBattlecardEditPath(currentBattlecard)));

  handleBattlecardDeleteClick = battlecardId => {
    const battlecard = this._findBattlecard(battlecardId);

    const onDeleteConfirm = () => {
      this.context.api.battlecardDelete(battlecard);
    };

    this.context.utils.dialog.confirm({
      message: `<strong>Delete battlecard &quot;${battlecard.title || '(Untitled Battlecard)'}&quot;?</strong><br /><br />
        Are you sure you want to delete this battlecard?`,
      okCallback: onDeleteConfirm
    });
  };

  handlePublishClick = battlecard => {
    const {dialog} = this.context.utils;
    const isDraft = !battlecard.isDraft;
    const title = battlecard.title || '(Untitled Battlecard)';
    const battlecardOptions = {
      id: battlecard.id,
      isDraft
    };

    dialog.remove('modal-battlecard-publish');

    this.context.api.battlecardCreateOrUpdate(battlecardOptions, () => {
      // force update .context data
      this.forceUpdate();

      const message = alertMessageForPublishedBattlecard(title, !isDraft);

      dialog.alert(message);
    });
  };

  extractDraftCards = (battlecard, boards) => {
    const cardTitlesMap = new Map(battlecard.cardTitles.map(title => [title.id, title.name]));
    const matchingCards = [];
    const profileId = battlecard.profile?.id;

    boards.forEach(board => {
      board.cards.forEach(card => {
        if(cardTitlesMap.has(card.id) && card.isDraft) {
          matchingCards.push({id: card.id, name: cardTitlesMap.get(card.id), profileId});
        }
      });
    });

    return matchingCards;
  };

  handleBattlecardDraftToggleClick = battlecard => {
    const {dialog} = this.context.utils;

    if(_.isEmpty(battlecard)) {
      return;
    }

    const draftCards = this.extractDraftCards(battlecard, this.props.boards);

    if(battlecard.isDraft && draftCards.length > 0) {
      const uiModalContent = (
        <div>
          <h3 className="heading-dialog">This battlecard has cards set to &apos;Curator Only&apos;.
            We suggest updating visibility permissions on the following:</h3>
          <ul>
            {draftCards.map((card, index) => (
              <li key={card.id}>
                <a href={
                `${window.location.origin}/profile/${card?.profileId}/edit/card/${card.id}/edit`}
                  target="_blank"
                  className="external-link">{card?.name || `Card #${index + 1}`}</a></li>
            ))}
          </ul>
          <div className="button-wrapper">
            <div className="button-legacy button-legacy--size-normal button-legacy--secondary button-legacy--caps"
              onClick={() => dialog.remove('modal-battlecard-publish')}><strong>Cancel</strong></div>
            <div className="button-legacy button-legacy--size-normal button-legacy--primary button-legacy--caps"
              onClick={() => this.handlePublishClick(battlecard)}><strong>Publish Anyway</strong></div>
          </div>
        </div>
      );

      dialog.create({
        id: 'modal-battlecard-publish',
        _wideMode: true,
        content: uiModalContent
      });
    }
    else {
      this.handlePublishClick(battlecard);
    }
  };

  handleBattlecardRenameClick = (battlecardId, inputRefName, event) => {
    this._preventDefault(event);

    const battlecard = this._findBattlecard(battlecardId);
    const battlecardNewName = this.refs[inputRefName] ? (this.refs[inputRefName].value || '') : '';

    if(battlecardNewName) {
      const battlecardOptions = {
        id: battlecard.id,
        profileId: battlecard.profile.id,
        title: battlecardNewName
      };

      this.context.api.battlecardCreateOrUpdate(battlecardOptions, () => {
        this.setState({
          renameModeBattlecardId: null
        });
      });
    }
  };

  handleBattlecardRenameCancelClick = () => this.setState({renameModeBattlecardId: null});

  handleBattlecardHelpClick = (battlecardId, event) => {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const battlecard = this._findBattlecard(battlecardId);

    this.context.utils.dialog.confirm({
      message: 'To let your team members know that a battlecard is up to date, you can “Mark as reviewed” when viewing the battlecard',
      okCallback() {
        this.props.history.push(`/profile/${battlecard.profile.id}/battlecard/${battlecardId}`);
      },
      cancelCallback: false,
      buttonOk: 'View Battlecard'
    });
  };

  handleShowBattlecardAddUIClick = () => this.setState({showAddBattlecardUI: true});

  handleManageAllClick = (currentBattlecard = null) => {
    this.setState({view: builderViews.MANAGE_ALL}, () => this.props.history.push(this._getBattlecardEditPath(currentBattlecard, true)));
  };

  handleAddNewBattlecardClick = event => {
    this._preventDefault(event);

    const {newBattlecardSaveObj} = this.props;
    const {api: {battlecardCreateOrUpdate}} = this.context;

    if(!newBattlecardSaveObj) {
      console.warn('BattlecardsEditor: cannot add new newBattlecardSaveObj template');

      return;
    }

    const battlecardNewName = (this._addBattlecardInputRef && this._addBattlecardInputRef.value) || '';

    if(!battlecardNewName) {
      return;
    }

    // merge data with default battlecard template
    const battlecardOptions = {
      profileId: newBattlecardSaveObj.profile.id,
      title: battlecardNewName
    };

    battlecardCreateOrUpdate(battlecardOptions);

    this.setState({showAddBattlecardUI: false});
  };

  handleBattlecardShowRenameUIClick = battlecardId => this.setState({renameModeBattlecardId: battlecardId});

  handleBattlecardDisplayModeClick = newMode => {
    this.setState({
      battlecardBuilderViewport: newMode
    });
  };

  handleOnDidUpdateBattlecardCards = ({error, userUpdateError, cardIds, updatedCards}) => {
    if(userUpdateError) {
      console.log('BattlecardsEditor.handleOnDidUpdateBattlecardCards: failed to update user default');
    }

    if(error) {
      const {utils: {dialog}} = this.context;

      dialog.alert(battlecardReviewedAlertCardErrorMessage);
    }

    const success = !error && !userUpdateError;

    if(success) {
      console.log('BattlecardsEditor.handleOnDidUpdateBattlecardCards: updated battlecard cards');

      const {onDidUpdateBattlecardCards} = this.props;

      onDidUpdateBattlecardCards({cardIds, updatedCards});
    }
  };

  handleBattlecardReviewClick = (battlecardId, event) => {
    this._preventDefault(event);

    const battlecard = this._findBattlecard(battlecardId);

    if(_.isEmpty(battlecard)) {
      return;
    }

    const battlecardOptions = {
      id: battlecard.id,
      profileId: battlecard.profile.id,
      markAsReviewed: true
    };

    const {api: {battlecardCreateOrUpdate}} = this.context;

    battlecardCreateOrUpdate(battlecardOptions, () => {
      return battlecardReviewedAlert({
        battlecards: [battlecard],
        context: this.context,
        onDidUpdateBattlecardCards: this.handleOnDidUpdateBattlecardCards
      });
    });
  };

  handleCancelNewBattlecardClick = () => this.setState({showAddBattlecardUI: false});

  handleBattlecardPreviewClick = (showBool = true) => this.setState({showCurrentBattlecardPreview: showBool});

  handleLayoutSelect = (layout, instanceLayout) => {
    const {utils: {battlecard: {id, profile: {id: profileId}}, featureFlagUpdate}, api: {battlecardCreateOrUpdate}} = this.context;

    battlecardCreateOrUpdate({
      id,
      profileId,
      layout
    }, () => {
      const featureFlagPath = ['defaults', 'battlecards', 'defaultLayout'];

      featureFlagUpdate(featureFlagPath, instanceLayout);
    });
  };

  renderRenameBattlecard = battlecardId => {
    const battlecard = this._findBattlecard(battlecardId);
    const currentName = battlecard.title || '';
    const inputRef = 'battlecardNameInput-' + battlecardId;

    return (
      <form className="faux-form" onSubmit={e => this.handleBattlecardRenameClick(battlecardId, inputRef, e)}>
        <input className="faux-form_submit" type="submit" />{/* Allows enter on field to trigger the form onSubmit handler*/}
        <table className="u-w100">
          <tbody>
            <tr>
              <td className="u-w100"><input ref={inputRef} defaultValue={currentName} autoFocus={true} type="text" className="form-control u-w100" /></td>
              <td>
                <div
                  className="button-legacy button-legacy--size-normal button-legacy--primary button-legacy--nowrap"
                  onClick={e => this.handleBattlecardRenameClick(battlecardId, inputRef, e)}>
                  Update Battlecard Name
                </div>
              </td>
              <td>
                <div
                  className="button-legacy button-legacy--size-square button-legacy--hover-grey"
                  onClick={this.handleBattlecardRenameCancelClick}><i className="fa fa-close" /></div>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    );
  };

  renderManagerListBattlecardItem = (battlecard = {}, defaultStatus) => {
    const uiDropdownOptions = [];
    const cards = dig(battlecard, 'cards.desktop', []);
    const {isDraft} = battlecard;
    const cardsCount = cards.filter(id => parseInt(id, 10) >= 0).length || 0;
    const uiDropdownButton = (<Icon icon="settings" width="20" height="20" className="bcbuilder-icon-settings" />);
    const uiDefaultIndicator = defaultStatus ? (
      <div><i className="fa fa-thumb-tack" /> <strong>{defaultStatus}</strong></div>
    ) : null;

    // TODO: extract inline styles when this area is next worked on...
    const uiReviewedMsg = (
      <div>
        <em>Last Reviewed
          <a href="#" onClick={e => this.handleBattlecardHelpClick(battlecard.id, e)} style={{fontSize: '12px'}}>
            {' '}<i className="u-text-muted fa fa-question-circle" />
          </a>:
          {' '}{battlecard.review && battlecard.review.reviewedAt ? moment(battlecard.review.reviewedAt).fromNow(true) + ' ago' : '(never)'}
        </em>
      </div>
    );

    uiDropdownOptions.push({
      value: 'edit',
      label: 'Rename',
      icon: 'fa-i-cursor',
      onOptionClick: () => this.handleBattlecardShowRenameUIClick(battlecard.id)
    });

    uiDropdownOptions.push({
      value: 'update',
      label: 'Edit',
      icon: 'fa-pencil',
      onOptionClick: () => this.handleBattlecardSelectClick(battlecard)
    });

    let optValue = 'Unpublish';
    let optLabel = 'unpublish';
    let optIcon = 'fa-eye-slash';

    if(isDraft) {
      optValue = 'publish';
      optLabel = 'Publish';
      optIcon = 'fa-eye';
    }

    uiDropdownOptions.push({
      value: optValue,
      label: optLabel,
      icon: optIcon,
      onOptionClick: () => this.handleBattlecardDraftToggleClick(battlecard)
    });

    uiDropdownOptions.push({
      value: 'del',
      label: 'Delete Battlecard',
      separator: true,
      icon: 'fa-trash',
      onOptionClick: () => this.handleBattlecardDeleteClick(battlecard.id)
    });

    return (
      <div className="bcbuilder-bc-list_item_content" key={`manager_list_bc_${battlecard.id}`}>
        <div className="bcbuilder-bc-list_item_header">
          <span
            className={classNames('bcbuilder-bc-list_item_name', {'bcbuilder-bc-list_item_name--draft': isDraft})}
            onClick={() => this.handleBattlecardSelectClick(battlecard)}>
            {battlecard.title || '(Untitled Battlecard)'}
          </span>
          {isDraft ? <span>&nbsp;<Icon icon="visibility-off" width="18" height="18" /></span> : null}
          &nbsp;<span
            className="notification-badge notification-badge--grey"
            data-tracking-id="battlecard-editor-battlecard-card-count">{cardsCount} {pluralize('card', cardsCount)}</span>
        </div>
        {uiDefaultIndicator}
        {uiReviewedMsg}
        <div className="bcbuilder-bc-list_item_actions">
          <Icon icon="reorder" width="20" height="20" className="bcbuilder-icon-reorder" />
          <Dropdown
            options={uiDropdownOptions}
            condensed={true}
            containerClass="ui-dropdown-flush"
            button={uiDropdownButton} />
        </div>
      </div>
    );
  };

  renderManagerListOfBattlecards = () => {
    const allBattlecards = this.state.allBattlecards || [];
    let uiList = [];

    allBattlecards.sort((a, b) => parseFloat(a.viewOrder) - parseFloat(b.viewOrder));

    const {firstPublishedBattlecard, firstBattlecardIsPublished} = allBattlecards.reduce((acc, bc, i) => {
      if(i === 0) {
        return {firstBattlecardIsPublished: !bc.isDraft};
      }

      const {firstPublishedBattlecard: firstPublished} = acc;

      if(!firstPublished && !bc.isDraft) {
        return {...acc, firstPublishedBattlecard: bc};
      }

      return acc;
    }, {});

    for(let i = 0; i < allBattlecards.length; i++) {
      let defaultStatus;

      if(i === 0) {
        defaultStatus = firstBattlecardIsPublished ? 'Default Battlecard' : 'Default Curator Battlecard';
      }
      else if(!firstBattlecardIsPublished && (firstPublishedBattlecard === allBattlecards[i])) {
        defaultStatus = 'Default Consumer Battlecard';
      }

      if(this.state.renameModeBattlecardId === allBattlecards[i].id) {
        uiList.push(
          <div key={i} className="bcbuilder-bc-list_item">
            {this.renderRenameBattlecard(allBattlecards[i].id)}
          </div>
        );
      }
      else {
        uiList.push(
          <BattlecardDraggable
            key={i}
            canDrag={true}
            canDrop={true}
            onHandleDrop={this.handleBattlecardReorder}
            className="bcbuilder-bc-list_item"
            droppableCardIndex={i}>
            {this.renderManagerListBattlecardItem(allBattlecards[i], defaultStatus)}
          </BattlecardDraggable>
        );
      }
    }

    if(!uiList.length) {
      uiList = (
        <div className="bcbuilder-bc-list_item" onClick={this.handleShowBattlecardAddUIClick}>
          <div className="text-center"><em>You haven&apos;t created any battlecards for this company yet.</em></div>
        </div>
      );
    }

    return (
      <div className="bcbuilder-bc-list u-mb-l">
        {uiList}
      </div>
    );
  };

  renderBattlecardSelectDropdown = currentBattlecard => {
    const {allBattlecards = []} = this.state;

    if(_.isEmpty(allBattlecards)) {
      // hide menu if no battlecards
      return;
    }

    const currentBattlecardIndex = currentBattlecard ? allBattlecards.findIndex(b => b.id === currentBattlecard.id) : -1;
    const uiDropdownOptions = [];
    let uiDropdownButton = null;

    for(let i = 0; i < allBattlecards.length; i++) {
      const uiDropdownOptionLabel = `#${i + 1} ${allBattlecards[i].title || '(Untitled Battlecard)'}`;

      uiDropdownOptions.push({
        value: i,
        selected: (allBattlecards[i].id === currentBattlecardIndex) && (this.state.view === builderViews.CURRENT_BATTLECARD),
        label: uiDropdownOptionLabel,
        onOptionClick: () => this.handleBattlecardSelectClick(allBattlecards[i])
      });
    }

    // manage dropdown option
    uiDropdownOptions.push({
      separator: true,
      selected: this.state.view === builderViews.MANAGE_ALL,
      value: 'manage',
      label: 'Manage/Add Battlecards',
      onOptionClick: () => this.handleManageAllClick(currentBattlecard)
    });

    if(this.state.view === builderViews.MANAGE_ALL) {
      uiDropdownButton = (
        <div className="button-legacy button-legacy--size-normal button-legacy--white">
          <span className="u-text-muted"><i className="fa fa-chevron-down" /></span>&nbsp;<strong>Manage/Add Battlecards</strong>
        </div>
      );
    }
    else {
      const {title} = currentBattlecard || {};
      const battlecardTitle = currentBattlecard ? (title || '(Untitled Battlecard)') : '';

      uiDropdownButton = (
        <div data-cy="battlecardDropdown" className="button-legacy button-legacy--size-normal button-legacy--white">
          <span className="u-text-muted">
            <i className="fa fa-chevron-down" />&nbsp;{currentBattlecardIndex + 1}/{allBattlecards.length}
          </span>&nbsp; <strong>{battlecardTitle}</strong>
        </div>
      );
    }

    uiDropdownButton = (
      <Dropdown
        options={uiDropdownOptions}
        condensed={true}
        alignMenuLeft={true}
        containerClass="ui-dropdown-flush"
        button={uiDropdownButton}
        dataTestId="manage-add-battlecards-dropdown" />
    );

    return uiDropdownButton;
  };

  renderAddBattlecard = () => {
    if(this.state.showAddBattlecardUI) {
      return (
        <form className="faux-form" onSubmit={this.handleAddNewBattlecardClick}>
          <input className="faux-form_submit" type="submit" />{/* Allows enter on field to trigger the form onSubmit handler*/}
          <label>New Battlecard Name</label>
          <table className="u-w100">
            <tbody>
              <tr>
                <td className="u-w100"><input type="text" ref={this._setAddBattlecardInputRef} autoFocus={true} className="form-control u-w100" /></td>
                <td>
                  <div
                    className="button-legacy button-legacy--size-normal button-legacy--primary button-legacy--nowrap"
                    onClick={this.handleAddNewBattlecardClick}
                    data-tracking-id="battlecard-editor-add-battlecard-button">
                    Add Battlecard
                  </div>
                </td>
                <td>
                  <div
                    className="button-legacy button-legacy--size-square button-legacy--hover-grey"
                    onClick={this.handleCancelNewBattlecardClick}>
                    <i className="fa fa-close" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      );
    }

    return (
      <div className="text-right">
        <div className="button" onClick={this.handleShowBattlecardAddUIClick}>
          Add New Battlecard
        </div>
      </div>
    );
  };

  renderCurrentBattlecardPreview = () => {
    if(!this.state.showCurrentBattlecardPreview) {
      return null;
    }

    const {battlecard, company: {updatedAt}} = this.context.utils;

    if(!battlecard) {
      return null;
    }

    return (
      <BattlecardPreviewProxy>
        <div className="battlecard-editor-preview">
          <div className="battlecard-editor-preview_toolbar">
            <div className="toolbar">
              <div className="toolbar_left toolbar--hide-mobile">
                <div className="toolbar-item">
                  <div className="toolbar-item_text">Previewing Battlecard</div>
                  <div className="toolbar-item_text toolbar-item_text--bold">{battlecard.title || '(Untitled Battlecard)'}</div>
                </div>
              </div>
              <div className="toolbar_right">
                <div className="toolbar-button" onClick={() => this.handleBattlecardPreviewClick(false)}>
                  <div className="toolbar-button_icon">
                    <Icon icon="close" width="24" height="24" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="battlecard-editor-preview_contain">
            <BattlecardView
              companyLastUpdated={updatedAt}
              maxBattlecardCards={8}
              showPreviewLinkOnCards={false} />
          </div>
        </div>
      </BattlecardPreviewProxy>
    );
  };

  render() {
    const {utils, appData: {v2Host}} = this.context;
    const {battlecard, user, isComposerEnabled} = utils;
    const showTryComposerButton = isComposerEnabled();
    const companyLayoutPrefs = dig(utils, 'company.companyData.defaults.battlecards.defaultLayout', '');
    const {view, battlecardBuilderViewport} = this.state;
    const {onBattlecardTileClicked, cardTitles, onViewAs} = this.props;
    const canSetDefault = battlecard ? userCanCurate({user}) : false;
    let uiBattlecardsEditorDnDBuilder;
    let uiManagerView;

    if((view === builderViews.CURRENT_BATTLECARD) && battlecard) {
      // TODO: extract inline styles when this area is next worked on...
      uiBattlecardsEditorDnDBuilder = (
        <div>
          <BattlecardsEditorDnDBuilder
            cardTitles={cardTitles}
            activeViewport={battlecardBuilderViewport}
            maxBattlecardCards={8}
            onBattlecardTileClicked={onBattlecardTileClicked} />
          <BattlecardsEditorLayouts
            battlecard={battlecard}
            handleLayoutSelect={this.handleLayoutSelect}
            canSetDefault={canSetDefault}
            companyLayoutPrefs={companyLayoutPrefs || null} />
          <div className="u-pt-m">
            Last Reviewed
            <a href="#" onClick={e => this.handleBattlecardHelpClick(battlecard.id, e)} style={{fontSize: '12px'}}>
              {' '}<i className="u-text-muted fa fa-question-circle" />
            </a>: {
              battlecard.review && battlecard.review.reviewedAt
                ? moment(battlecard.review.reviewedAt).fromNow(true) + ' ago'
                : '(Never)'
            } &bull; <a href="#" style={{whiteSpace: 'nowrap'}} onClick={e => this.handleBattlecardReviewClick(battlecard.id, e)}>
              Mark as Reviewed
            </a>
          </div>
          <div className="battlecard-editor--preview-wrapper">
            <strong>Preview Battlecard:</strong>
            <ViewAsDropdown key="battlecard-view-as-dropdown" trackingId="battlecard-toolbar" onViewAs={onViewAs} />
          </div>

        </div>
      );

      const displayModeClass = 'pull-left button-legacy button-legacy--size-square';
      const displayModeMobileClass = classNames(displayModeClass, {
        'button-legacy--border-grey': battlecardBuilderViewport === uiViewports.MOBILE,
        'button-legacy--hover-grey': battlecardBuilderViewport !== uiViewports.MOBILE
      });
      const displayModeTabletClass = classNames(displayModeClass, {
        'button-legacy--border-grey': battlecardBuilderViewport === uiViewports.TABLET,
        'button-legacy--hover-grey': battlecardBuilderViewport !== uiViewports.TABLET
      });
      const displayModeDesktopClass = classNames(displayModeClass, {
        'button-legacy--border-grey': battlecardBuilderViewport === uiViewports.DESKTOP,
        'button-legacy--hover-grey': battlecardBuilderViewport !== uiViewports.DESKTOP
      });
    }
    else {
      uiManagerView = (
        <div>
          {this.renderManagerListOfBattlecards()}
          {this.renderAddBattlecard()}
          {showTryComposerButton && v2Host && <BattlecardTryComposer v2Host={v2Host} />}
        </div>
      );
    }

    return (
      <div ref="bcbuilder" className="bcbuilder-panel-wrap">
        <div className="battlecards-editor_header-controls">
          <div className="left-side">
            {this.renderBattlecardSelectDropdown(battlecard)}
            {!uiManagerView && battlecard && battlecard.isDraft
              ? <div className="draft-eye"><Icon icon="visibility-off" width="18" height="18" /></div>
              : null
            }
          </div>
        </div>
        {uiBattlecardsEditorDnDBuilder}
        {uiManagerView}
        {this.renderCurrentBattlecardPreview()}
      </div>
    );
  }

}

export default withRouter(BattlecardsEditor);

import {useCallback, useEffect} from 'react';
import {analyticsTrack, SNOWPLOW_SCHEMAS} from '../modules/analytics_utils';

const NoDataMessage = ({cardId, cardCreatedAt, analyticsEventContext, isDraft}) => {
  const trackEvent = useCallback(() => {
    const {viewContext} = analyticsEventContext || {};

    if(cardCreatedAt) {
      const cardCreatedDate = new Date(cardCreatedAt);
      const oneDayAgo = new Date();

      oneDayAgo.setDate(oneDayAgo.getDate() - 1);

      if(cardCreatedDate < oneDayAgo) {
        const trackingData = {
          schema: SNOWPLOW_SCHEMAS.impression,
          data: {
            type: 'card',
            view: viewContext
          },
          context: [
            {
              schema: SNOWPLOW_SCHEMAS.card,
              data: {
                id: cardId,
                dynamicBlockHasData: false,
                status: isDraft ? 'draft' : 'published'
              }
            }
          ]
        };

        analyticsTrack({
          type: 'event',
          spOnly: true
        }, trackingData);
      }
    }
  }, [cardId, cardCreatedAt, analyticsEventContext, isDraft]);

  useEffect(() => {
    trackEvent();
  }, [trackEvent]);

  return <p className="template-attachment--no-data">We don&#39;t have this data yet. We will update this card as soon as something is available.</p>;
};

NoDataMessage.propTypes = {
  cardId: PropTypes.number.isRequired,
  cardCreatedAt: PropTypes.string,
  analyticsEventContext: PropTypes.object,
  isDraft: PropTypes.bool
};

NoDataMessage.defaultProps = {
  cardCreatedAt: '',
  analyticsEventContext: {},
  isDraft: false
};

export default NoDataMessage;

import {FormSpy} from 'react-final-form';

import InputSelectorGroup from '../../components/_input_selector_group';
import Condition from '../_form_condition';

import {allGroupItems} from '../../modules/new_board_wizard_utils';

const AddCards = ({templateGroups, automatedCardGroups}) => {
  const handleSelectAll = (groups, name, form) => form.change(name, allGroupItems(groups));

  const handleClearAll = (name, form) => form.change(name, []);

  return (
    <FormSpy subscription={{values: false}}>
      {({form}) => (
        <div className="add-cards">
          <InputSelectorGroup
            title="Clone from Template"
            titleDataTrackingId="new-board-clone-from-template-label"
            name="template"
            label="Template"
            groups={templateGroups} />
          <Condition when="boardType" is={type => ['company', 'product'].includes((type?.id || '').toLowerCase())}>
            <InputSelectorGroup
              title="Add Automated Cards"
              titleDataTrackingId="new-board-add-automated-cards-label"
              name="automatedCards"
              label="Automated Cards"
              type="checkbox"
              groups={automatedCardGroups}
              onSelectAll={() => handleSelectAll(automatedCardGroups, 'automatedCards', form)}
              onClearAll={() => handleClearAll('automatedCards', form)} />
          </Condition>
        </div>
      )}
    </FormSpy>
  );
};

AddCards.propTypes = {
  templateGroups: PropTypes.arrayOf(PropTypes.object),
  automatedCardGroups: PropTypes.arrayOf(PropTypes.object)
};

AddCards.defaultProps = {
  templateGroups: [],
  automatedCardGroups: []
};

export default AddCards;

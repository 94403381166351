import Types from '../../types';
import * as KlueTypes from '../../klue_types';

import {fetchCraftData} from './data_providers';
import {parseHostname} from '../../../modules/url_utils';

export async function companySummary(company, craftDataFetcher = fetchCraftData) {
  if(!KlueTypes.Company.validate(company)) {
    console.warn('Unexpected parameter type.', company);
    throw new Error('Parameter was unexpected type.');
  }

  const data = await craftDataFetcher(company.info);
  const summaryFacts = [];
  const headquarters = (data.locations) ? data.locations.find(loc => loc.hq) : null;
  const employees = data.employees.reduce((memo, record) => {
    if(memo.date === null || memo.date < record.date) {
      return {date: record.date, count: record.employeeNumber};
    }

    return memo;
  }, {date: null, count: 0});

  if(headquarters) {
    summaryFacts.push(KlueTypes.SummaryFact(Types.string).create({
      label: 'HQ',
      value: `${headquarters.city}, ${headquarters.country}`
    }));
  }

  if(employees.count > 0) {
    summaryFacts.push(KlueTypes.SummaryFact(Types.string).create({
      label: 'Employees',
      value: `${employees.count} (est.)`
    }));
  }

  if(data.foundedYear) {
    summaryFacts.push(KlueTypes.SummaryFact(Types.string).create({
      label: 'Founded',
      value: data.foundedYear
    }));
  }

  if(data.homepage) {
    summaryFacts.push(KlueTypes.SummaryFact(KlueTypes.Link).create({
      label: 'Homepage',
      value: KlueTypes.Link.create({
        url: data.homepage,
        title: parseHostname(data.homepage)
      })
    }));
  }

  return Types.arrayOf(KlueTypes.SummaryFact()).create(summaryFacts);
}

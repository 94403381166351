import {useEffect} from 'react';
import {resizeExtension, klueIsInExtension, SRC_PARAM as extensionSrcParam} from '../modules/ext_utils';
import {klueIsInTeams, useMicrosoftTeams, SRC_PARAM as teamsSrcParam} from '../hooks/useMicrosoftTeams';
import KlueLogo from '../../images/klue-logo-black.svg';
import mastImagePath from '../../images/klue-aiw.svg';

const LoginEmbed = (props = {}) => {
  const {logoPath} = props;
  const {teams} = useMicrosoftTeams();
  const inTeams = klueIsInTeams();
  const inExtension = klueIsInExtension();
  const src = inExtension ? extensionSrcParam : inTeams ? teamsSrcParam : '';
  const params = new URL(document.location.toString()).searchParams;
  let idpId = params.get('idpId');

  if(!idpId) {
    const redirectTo = params.get('redirectTo');

    if(redirectTo) {
      const url = new URL(`${window.location.origin}${redirectTo}`);

      idpId = url.searchParams.get('idpId');
    }
  }

  const authStart = (inTeams && idpId) ? `/account/auth/saml/${idpId}` : '/account/signin';
  const authEnd = '/embed-signin-complete';
  const loginMessage = inTeams
    ? 'You are not logged in. Please authenticate to use Klue in Microsoft Teams.'
    : 'Please log in to access your Klue content. Refresh this page once you are logged in.';
  let authPath = authStart;

  if(!idpId) {
    authPath += `?redirectTo=${encodeURIComponent(`${authEnd}${src ? `?src=${src}` : '' }`)}`;
  }

  const handleTeamsLoginClick = () => {
    teams?.authentication?.authenticate({
      url: `${window.location.origin}${authPath}`,
      width: 420,
      height: 800,
      successCallback() {
        console.log('MS Teams SDK: authentication successful');
      },
      failureCallback(error) {
        console.error('MS Teams SDK: authentication failed', error);
      }
    });
  };

  useEffect(
    () => {
      inExtension && resizeExtension();

      return () => {
        return inExtension ? resizeExtension() : null;
      };
    },
    [inExtension]
  );

  return (
    <div className="layout-login" data-testid="login-embed">
      <div className="layout-login_mast">
        <div className="layout-login_mast_body">
          <img alt="Klue All-in-Won" src={mastImagePath} width="409" height="188" />
          <h2>
            Combine market, competitor, and buyer insights in a unified
            platform, driven by AI. Find the way to win.
          </h2>
        </div>
      </div>
      <div className="layout-login_panel">
        <div className="layout-login_panel_body layout-login_panel_body--grow layout-login_panel_body--vpad">
          <div className="layout-login_panel_body_vertical">
            <div className="layout-login-contain layout-login-contain--logo">
              <img alt="Klue" src={logoPath || KlueLogo} />
            </div>
            <div className="layout-login_panel_embedded_content">
              <p>{loginMessage}</p>
              {inTeams
                ? (teams.isInitialized !== false) && <button
                    className={`button button--full button--next${!teams.isInitialized ? ' button--disabled' : ''}`}
                    data-testid="login-embed-button_msteams"
                    disabled={!teams.isInitialized}
                    onClick={teams.isInitialized ? handleTeamsLoginClick : null}
                    style={{marginTop: '1rem'}}>
                  {teams.isInitialized ? 'Login' : 'Initializing…'}
                </button>
                : <button
                    className="button button--full button--next"
                    data-testid="login-embed-button"
                    onClick={() => window.open(authPath, '_blank')}
                    style={{marginTop: '1rem'}}>
                  Login
                </button>}
              {inTeams && (teams.isInitialized === false)
                ? <div className="alert alert-warning" data-testid="login-embed_msteams_error">
                  <strong>There was a problem initializing Microsoft Teams</strong>.
                  Please reload/restart Teams and try again or contact your Klue administrator if the problem persists.
                </div>
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

LoginEmbed.propTypes = {
  logoPath: PropTypes.string
};

LoginEmbed.defaultProps = {
  logoPath: undefined
};

export default LoginEmbed;

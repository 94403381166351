import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {sfdcFormulaTypes} from '../../../../../modules/constants/formulas';
import {camelToSnake} from '../../../../../modules/text_utils';
import {userCanCurate} from '../../../../../modules/roles_utils';
import {getFormulaKey} from '../../../../../modules/editor_utils';
import KlueTypeComponentFactory from '../../../charts/_klue_type_component_factory';
import SalesforceBuilder from '../../template_editor/formula_wizard/formula_builders/_salesforce_builder';
import ReportDataInaccuracies from '../../../../_report_data_inaccuracy';
import NoDataMessage from '../../../../_no_data_message';

function computeCodeHash(code) {
  return code.trim();
}

class FormulaDisplay extends React.Component {

  static contextTypes = {
    utils: PropTypes.object.isRequired,
    appData: PropTypes.object.isRequired
  };

  static propTypes = {
    code: PropTypes.string.isRequired,
    rival: PropTypes.object.isRequired,
    lane: PropTypes.object.isRequired,
    cardId: PropTypes.number,
    cardTitle: PropTypes.string,
    cardCreatedAt: PropTypes.string,
    isDraft: PropTypes.bool,
    codeResults: PropTypes.object.isRequired,
    onCodeNeeded: PropTypes.func.isRequired,
    analyticsEventContext: PropTypes.object
  };

  static defaultProps = {
    code: '',
    rival: null,
    lane: null,
    cardId: 0,
    cardTitle: '',
    cardCreatedAt: '',
    isDraft: false,
    codeResults: null,
    onCodeNeeded() {},
    analyticsEventContext: {}
  };

  componentDidMount() {
    console.log('FormulaDisplay.componentDidMount: props: %o', this.props);

    const {code, onCodeNeeded} = this.props;

    onCodeNeeded(code);
  }

  shouldComponentUpdate = nextProps => {
    const {code, codeResults} = this.props;

    if(code !== nextProps.code) {
      return true;
    }

    const hash = computeCodeHash(code);
    const originalResult = codeResults ? codeResults.get(hash) : null;
    const newResult = nextProps.codeResults ? nextProps.codeResults.get(hash) : null;

    return originalResult !== newResult;
  };

  componentDidUpdate() {
    console.log('FormulaDisplay.componentDidUpdate: props: %o', this.props);

    const {code, onCodeNeeded} = this.props;

    onCodeNeeded(code);
  }

  getCurrentResult(code) {
    const {codeResults} = this.props;
    let result = null;

    if(codeResults) {
      result = codeResults.get(computeCodeHash(code)) || null;
    }

    if(result && result.result) {
      const {data = [], events = [], imageSrcUrl = [], pairs = [], portions = [], records = [], rawHtml = ''} = result.result;
      const results = [].concat(data, events, imageSrcUrl, pairs, portions, (records.rows ? records.rows : records),
        (result.result.length ? result.result : []), rawHtml);

      if(_.isEmpty(result.result) || results.length === 0) {
        result = {error: `No data for ${code}`};
      }
    }

    return result;
  }

  render() {
    const {code, rival, cardId, cardTitle, lane, cardCreatedAt, analyticsEventContext, isDraft} = this.props;
    const key = getFormulaKey(code);
    const result = this.getCurrentResult(code);

    if(window.Cypress) {
      return (
        <span>{`{{${code}}}`}</span>
      );
    }

    // workaround to get sfdc contents working in static html templates
    // TODO: after we remove alloy and edit mode, we might want to create a single sfdc
    // responsible for rendering the content
    if(sfdcFormulaTypes.includes(key)) {
      const funcName = camelToSnake(key).toUpperCase();

      return (
        <SalesforceBuilder funcName={funcName} displayOnly={true} rival={rival} formula={code} />
      );
    }

    if(!result) {
      return (<div className="dynamic-data-loading">Loading data...</div>);
    }

    const {utils: {user}, appData} = this.context;
    const showReportIncorrectDataButton = userCanCurate({user});
    const cardURL = `${appData.rootUrl || ''}card/${cardId}`;

    if(result.error) {
      return (
        <>
          <NoDataMessage
            cardId={cardId}
            cardCreatedAt={cardCreatedAt}
            isDraft={isDraft}
            analyticsEventContext={analyticsEventContext} />
          <ReportDataInaccuracies
            visible={showReportIncorrectDataButton}
            user={user}
            rival={rival}
            lane={lane}
            cardURL={cardURL}
            cardTitle={cardTitle} />
        </>
      );
    }

    return (
      <>
        <KlueTypeComponentFactory
          val={result.result}
          cardId={cardId}
          cardCreatedAt={cardCreatedAt}
          isDraft={isDraft}
          analyticsEventContext={analyticsEventContext} />
        <ReportDataInaccuracies
          visible={showReportIncorrectDataButton}
          user={user}
          rival={rival}
          lane={lane}
          cardURL={cardURL}
          cardTitle={cardTitle} />
      </>
    );
  }

}

const mapStateToProps = ({cards}) => ({codeResults: cards.codeResults});

const mapDispatchToProps = dispatch => ({
  onCodeNeeded(code) {
    const formulaKey = getFormulaKey(code);

    if(sfdcFormulaTypes.includes(formulaKey)) {
      return;
    }

    return dispatch.cards.cardsExecuteCode(code);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(FormulaDisplay);

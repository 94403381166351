import {FormSpy} from 'react-final-form';
import CompanySelector from '../_company_selector';
import InputSelectorGroup from '../_input_selector_group';
import Condition from '../_form_condition';
import TextField from '../_form_text_field';
import BoardLogoFormField from '../../components/_board_logo_form_field';
import {validateBoardName} from '../../modules/new_board_wizard_utils';
import {isValidUrl} from '../../modules/url_utils';

const BOARD_TYPES = {
  company: {name: 'Company', id: 'company'},
  product: {name: 'Product', id: 'product'},
  other: {name: 'Blank', id: 'other'}
};

const InitialStep = ({rivals}, {appData}) => {
  const boardTypesGroup = [{title: 'types', items: Object.values(BOARD_TYPES)}];
  const allowlistedRivalNames = (name || '').length ? [name.toLowerCase()] : [];
  const isBoardTypeProductOrCompany = type =>
    [BOARD_TYPES.company.id, BOARD_TYPES.product.id].includes(
      (type.id || '').toLowerCase()
    );

  const handleTypeChange = ({selected, form, currentBoardType}) => {
    const {batch, change} = form;

    if(selected?.id === BOARD_TYPES.other.id) {
      batch(() => {
        change('boardName', 'My New Blank Board');
        change('companyName', 'none');
        change('logoUrl', `${appData.rootUrl}klue-logo-128x128.png`);
      });
    }

    if(currentBoardType?.id === BOARD_TYPES.other.id) {
      batch(() => {
        change('boardName', undefined);
        change('companyName', undefined);
        change('logoUrl', undefined);
      });
    }
  };

  const handleCompanySelection = (company, form) => {
    const {name, url, logoUrl, isAddNewCompany} = company;

    const {batch, change} = form;

    batch(() => {
      change('companyName', name);
      change('boardName', name);
      change('companyUrl', url);
      change('logoUrl', logoUrl);
      change('isAddNewCompany', isAddNewCompany);
    });
  };

  const normilizeUrl = value => {
    // if value is undefined or already had the https we don't need to edit it.
    if(!value || value.match(/^https?:\/\//)) {
      return value;
    }
    // making sure that https:// is always there
    else if(value.length > 1 && value.length < 8) {
      return 'https://';
    }

    return 'https://' + value;
  };

  const validateUrl = value => (isValidUrl(value) ? undefined : 'URL is not valid');

  return (
    <FormSpy subscription={{values: true}}>
      {({values: {boardType, companyName, companyUrl, logoUrl, boardName, isAddNewCompany} = {}, form}) => {
        const isCompanySelectorFocused = Boolean(boardType && !companyName);
        const isCompanyUrlFocused = Boolean(companyName && !isValidUrl(companyUrl));
        const isBoardNameFocused = Boolean((isAddNewCompany && isValidUrl(companyUrl)) || (!isAddNewCompany && companyName));

        return (
          <div>
            <InputSelectorGroup
              title="What type of board do you need?"
              name="boardType"
              label="Board Type"
              titleDataTrackingId="new-board-what-type-label"
              selectorDataTrackingId="new-board-input-selector"
              groups={boardTypesGroup}
              onItemClick={selected =>
                handleTypeChange({
                  selected,
                  form,
                  currentBoardType: boardType
                })
              }
              isFocused={!boardType} />
            <Condition
              when="boardType"
              is={isBoardTypeProductOrCompany}>
              <CompanySelector
                rivals={rivals}
                selectedName={companyName}
                selectedUrl={companyUrl}
                onCompanySelection={company => {
                  handleCompanySelection(company, form);
                }}
                isFocused={isCompanySelectorFocused} />
            </Condition>
            <Condition when="companyName" is={name => name}>
              <Condition when="isAddNewCompany" is={isNew => isNew === true}>
                <TextField
                  name="companyUrl"
                  defaultValue="https://"
                  displayName="Company URL"
                  parse={normilizeUrl}
                  validate={validateUrl}
                  placeholder="example: klue.com"
                  isFocused={isCompanyUrlFocused} />
              </Condition>
              <TextField
                name="boardName"
                displayName="Board Name"
                validate={value => validateBoardName(value, rivals || [], allowlistedRivalNames)}
                isFocused={isBoardNameFocused} />
              <BoardLogoFormField url={logoUrl} boardName={boardName} />
            </Condition>
          </div>
        );
      }}
    </FormSpy>
  );
};

InitialStep.propTypes = {
  rivals: PropTypes.arrayOf(PropTypes.object)
};

InitialStep.defaultProps = {
  rivals: []
};

InitialStep.contextTypes = {
  appData: PropTypes.object.isRequired
};
export default React.memo(InitialStep);

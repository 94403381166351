import OverlayLoader from './_overlay_loader';
import OverlayLoaderBoardReadyButtons from './_overlay_loader_board_ready_buttons';
import DashboardToolbar from './_dashboard_toolbar';
import DashboardView from './_dashboard_view';
import DashboardEdit from './_dashboard_edit';

import {dig, isValidId} from '../modules/utils';
import {filterRivals} from '../modules/rival_utils';
import {userCanCurate} from '../modules/roles_utils';
import {uiDefaultTypes, uiMessages, uiDelays} from '../modules/constants/ui';
import {setLocalRivalLogoUrl} from '../modules/local_storage_utils';
import {overlayMessages} from '../modules/overlay_loader_utils';
import {redirectToV2} from '../modules/route_utils';

import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import URI from 'urijs';
import {rivalGet} from '../modules/api/rivals';
import {jobStatus} from '../modules/api/jobStatus';

const checkProfileReady1stTimeInterval = 3000; // 3 seconds
const checkProfileReadyInterval = 5000; // 5 seconds
const profileStatus = {
  IDLE: 0,
  COMPLETED: 1,
  FAILURE: 2
};

class Dashboard extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired,
    appData: PropTypes.object.isRequired
  };

  static propTypes = {
    user: PropTypes.object,
    company: PropTypes.object,
    rivals: PropTypes.arrayOf(PropTypes.object),
    totalRivals: PropTypes.number,
    rivalGroups: PropTypes.arrayOf(PropTypes.object),
    editMode: PropTypes.bool.isRequired,
    onDisplayChange: PropTypes.func.isRequired,
    onGetUIDefaults: PropTypes.func.isRequired,
    onEnqueueToastMessage: PropTypes.func.isRequired,
    onClearToastMessages: PropTypes.func.isRequired,
    __onboardBCActive: PropTypes.bool.isRequired,
    messageId: PropTypes.string,
    history: PropTypes.object
  };

  static defaultProps = {
    user: null,
    company: null,
    rivals: null,       // null = not finished loading; [] = no rivals found for company
    totalRivals: 0,
    rivalGroups: null,
    editMode: false,
    onDisplayChange() {},
    onGetUIDefaults() {},
    onEnqueueToastMessage() {},
    onClearToastMessages() {},
    messageId: null,
    history: {}
  };

  constructor(props) {
    super(props);

    const defaultState = {
      activeGroupId: null,
      activeEditGroupId: 0,
      sortedRivals: [],
      companyAddOpen: false,
      rivalsOrder: '',
      companyFilter: '',
      collapsedGroups: [],
      waitingOnProfileId: null,
      profileReadyStatus: profileStatus.IDLE // 0 - IDLE, 1 - status is completed, 2 -status is failure
    };

    const url = URI(window.location.href);

    if(url.hasQuery('group')) {
      defaultState.activeGroupId = parseInt(url.query(true).group, 10);
    }

    this.state = defaultState;
    this.mounted = false;
  }

  componentDidMount() {
    const {rivalGroups, rivals, messageId, onDisplayChange} = this.props;
    const {title = '', message = '', isError = false} = uiMessages({id: messageId}) || {};

    console.log('Dashboard.componentDidMount: props: %o', this.props);

    if(this.dashboardView) {
      this.dashboardView.addEventListener('keydown', this._onEnterKeyPressed);
    }

    if(rivalGroups) {
      this.loadDashboardDefaults(this.props, () => {
        if(rivals) {
          this.sortRivals();
        }
      });
    }

    const url = URI(window.location.href);

    if(url.hasQuery('group')) {
      onDisplayChange().then(() => {
        this.handleSaveUserDefaults();
      });
    }

    if(title && message) {
      this.props.onEnqueueToastMessage({
        title,
        message,
        duration: 0,
        isError,
        source: 'Dashboard'
      });
    }

    this.mounted = true;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {rivals, rivalGroups, editMode, totalRivals} = this.props;
    const {rivals: nextRivals, rivalGroups: nextRivalGroups, editMode: nextEditMode, totalRivals: nextTotalRivals} = nextProps;

    if(!nextRivalGroups) {
      return;
    }

    const rivalsChanged = (rivals && nextRivals && (rivals.length !== nextRivals.length));
    const rivalsCleared = rivals && !nextRivals;
    const rivalsAddedOrRemoved = totalRivals !== nextTotalRivals;

    if(!rivalGroups && nextRivalGroups) {
      this.loadDashboardDefaults(nextProps);
    }

    if(rivalsChanged || rivalsCleared || rivalsAddedOrRemoved) {
      this.sortRivals(nextProps);
    }
    else if(editMode && !nextEditMode) {
      // clear company filter when switching between edit/view modes (logic re-used for both)
      this.setState({companyFilter: ''}, () => this.sortRivals(nextProps));
    }
  }

  componentWillUnmount() {
    this.props.onClearToastMessages('Dashboard');
    this.dashboardView && this.dashboardView.removeEventListener('keydown', this._onEnterKeyPressed);
    this.mounted = false;
  }

  _onEnterKeyPressed = event => {
    const {activeElement} = document;

    if(event && (event.key === 'Enter') && (activeElement.classList.contains('dashboard-card'))) {
      const link = activeElement.querySelector('.dashboard-card_link');

      event.preventDefault();
      link && link.click();
    }
  };

  _getCompanyData = (props = this.props) => {
    const {company} = props;

    if(_.isEmpty(company)) {
      return;
    }

    return company.companyData || {};
  };

  _getActiveGroup = () => {
    const rivalGroups = (this.props.rivalGroups || []).slice();
    const {activeGroupId} = this.state;

    if(!rivalGroups.length || !activeGroupId) {
      return null;
    }

    return rivalGroups.find(g => g.id === activeGroupId);
  };

  _isInActiveGroup = rivalId => {
    const rivalGroups = this.props.rivalGroups || [];
    const activeGroup = this._getActiveGroup();

    if(!rivalGroups.length || !activeGroup) {
      return false;
    }

    return (activeGroup.rivals || []).some(r => r.id === rivalId);
  };

  _findGroup = (groupId, props = this.props) => (props.rivalGroups || []).find(g => g.id === groupId);

  _getGroupTemplate = () => ({
    id: 0,
    name: '',
    userId: this.props.user.id,
    rivals: [],
    createdAt: null,
    updatedAt: null
  });

  setActiveEditGroupId = groupId => this.setState({activeEditGroupId: groupId});

  handleDashboardError = ({title = '', message = ''}) => {
    if(!title || !message) {
      return;
    }

    this.props.onEnqueueToastMessage({
      title,
      message,
      duration: uiDelays.toastError,
      isError: true,
      source: 'Dashboard'
    });
  };

  loadDashboardDefaults = (props = this.props, callback) => {
    const dashboardDefaults = props.onGetUIDefaults(uiDefaultTypes.DASHBOARD);
    const {__onboardBCActive} = props;

    if(__onboardBCActive) {
      this.setState({rivalsOrder: 'battlecards'});
    }
    else if(!_.isEmpty(dashboardDefaults)) {
      const {sortBy: rivalsOrder = 'cards', activeGroupId = 0, collapsedGroups = []} = dashboardDefaults;
      const {activeGroupId: currentActiveGroupId} = this.state;

      this.setState({
        rivalsOrder,
        activeGroupId: currentActiveGroupId || activeGroupId,
        collapsedGroups
      }, () => {
        console.log(
          'Dashboard.loadDashboardDefaults: loaded defaults: %o, rivalsOrder: %o, activeGroupId: %o',
          dashboardDefaults, this.state.rivalsOrder, this.state.activeGroupId
        );

        if(callback) {return callback();}
      });
    }
    else {
      // no company/user defaults, or user is impersonating
      console.info('Dashboard.loadDashboardDefaults: no defaults set, using system defaults');

      this.setState({
        activeGroupId: 0,       // default to "all companies" view
        rivalsOrder: 'cards'
      });
    }
  };

  handleAddRivalClick = event => {
    if(event) {
      event.preventDefault();
    }

    this.onToggleCompanyAddClick();
  };

  handleToggleGroup = (group = null, isActive = false) => {
    if(_.isEmpty(group)) {
      return;
    }

    const {collapsedGroups = []} = this.state;

    if(!isActive && !collapsedGroups.includes(group.id)) {
      collapsedGroups.push(group.id);
    }
    else if(isActive && collapsedGroups.includes(group.id)) {
      collapsedGroups.splice(collapsedGroups.indexOf(group.id), 1);
    }

    this.setState({collapsedGroups}, () => {
      console.log('Dashboard.handleToggleGroup: updated collapsed groups: %o', this.state.collapsedGroups);

      this.handleSaveUserDefaults();
    });
  };

  handleToggleGroupMembership = ({rivalId = 0, groupId = 0, remove = false}, callback = null) => {
    if(!isValidId(rivalId) || !isValidId(groupId)) {
      return;
    }

    const {rivalGroups = []} = this.props;
    const group = rivalGroups.find(g => g.id === groupId) || {};
    const groupRivals = (group.rivals || []).map(r => r.id) || [];

    if(_.isEmpty(group)) {
      // invalid group
      return false;
    }

    if(groupRivals.includes(rivalId) && remove) {
      // explicitly removing rival from group
      groupRivals.splice(groupRivals.indexOf(rivalId), 1);
    }
    else if(!groupRivals.includes(rivalId)) {
      // rival doesn't exist in group
      groupRivals.push(rivalId);
    }

    const rivalGroupOptions = {
      id: group.id,
      rivals: groupRivals
    };

    console.log('Dashboard.handleToggleGroupMembership: toggling rivalId #%o to groupId #%o: %o', rivalId, groupId, rivalGroupOptions);

    this.context.api.rivalGroupUpdate(rivalGroupOptions, updatedGroup => {
      this.sortRivals();

      console.log('Dashboard.handleToggleGroupMembership: toggled rivalId #%o to groupId #%o: %o', rivalId, groupId, updatedGroup);

      return typeof callback === 'function' && callback();
    });
  };

  onToggleCompanyAddClick = () => new Promise(resolve => {
    this.setState(prevState => ({companyAddOpen: !prevState.companyAddOpen}), () => resolve());
  });

  isProfileIsReady = (rival, profileId) => {
    const {statusId} = rival;

    jobStatus({statusId})
      .then(({completedAt, failedAt}) => {
        console.log(`completedAt: ${completedAt}; failedAt: ${failedAt}`);

        const {waitingOnProfileId} = this.state;

        if(!this.mounted || waitingOnProfileId === null || (profileId !== waitingOnProfileId)) {
          return;
        }

        if(failedAt) {
          return this.setState({profileReadyStatus: profileStatus.FAILURE});
        }

        if(completedAt) {
          return this.setState({profileReadyStatus: profileStatus.COMPLETED});
        }

        setTimeout(() => this.isProfileIsReady(rival, profileId), checkProfileReadyInterval);
      }).catch(error => {
        console.error(`profileIsReady error: ${error}`);

        const {waitingOnProfileId} = this.state;

        if(!this.mounted || waitingOnProfileId === null || (profileId !== waitingOnProfileId)) {
          return;
        }

        setTimeout(() => this.isProfileIsReady(rival, profileId), checkProfileReadyInterval);
      });
  };

  checkProfileReady = (profileId, theRival = null) => {
    const {waitingOnProfileId} = this.state;

    if(waitingOnProfileId === null || (profileId !== waitingOnProfileId)) {
      return;
    }

    if(theRival) {
      return this.isProfileIsReady(theRival, profileId);
    }

    const rivalOptions = {profileId};

    rivalGet(rivalOptions, 'Dashboard.checkProfileReady')
      .then(rival => {
        if(!this.mounted) {
          return;
        }

        this.isProfileIsReady(rival, profileId);
      })
      .catch(() => {
        if(!this.mounted) {
          return;
        }

        setTimeout(() => this.checkProfileReady(waitingOnProfileId), checkProfileReadyInterval);
      });
  };

  handleRivalCreated = (rival, rivalOptions, resolve, reject) => {
    const {profile} = rival;
    const {id: waitingOnProfileId} = profile || {};

    if(!waitingOnProfileId) {
      this.setState({waitingOnProfileId: null});

      return reject();
    }

    this.setState({
      waitingOnProfileId,
      waitingOnRival: rival,
      waitingOnOptions: rivalOptions
    }, () => {
      const {api: {refreshGroupsForRival}} = this.context;
      const {rivalGroups = [], imageUrl} = rivalOptions;

      if(imageUrl) {
        setLocalRivalLogoUrl(rival, imageUrl);
      }

      if(rivalGroups && rivalGroups.length) {
        refreshGroupsForRival(rival, rivalGroups.map(id => ({id, action: 'add'})))
          .then(() => {
            this.sortRivals();
            resolve(rival);

            setTimeout(() => this.checkProfileReady(waitingOnProfileId, rival), checkProfileReady1stTimeInterval);
          }).catch(err => this.setState({
            waitingOnProfileId: null
          }, () => reject(err)));
      }
      else {
        this.sortRivals();
        resolve(rival);

        setTimeout(() => this.checkProfileReady(waitingOnProfileId, rival), checkProfileReady1stTimeInterval);
      }
    });
  };

  handleCreateBoard = (rivalOptions = {}) => {
    return new Promise((resolve, reject) => {
      if(_.isEmpty(rivalOptions)) {
        return reject({message: 'No `rivalOptions` specified'});
      }

      this.setState({
        companyAddOpen: false,
        waitingOnProfileId: 0, // a non-null value means we're about to wait on a board id
        profileReadyStatus: profileStatus.IDLE
      }, () => {
        const {api: {rivalCreate}} = this.context;

        rivalCreate({rivalOptions})
          .then(rival => {
            this.handleRivalCreated(rival, rivalOptions, resolve, reject);
          }).catch(err => this.setState({
            waitingOnProfileId: null
          }, () => reject(err)));
      });
    });
  };

  handleSaveUserDefaults = () => {
    const {user} = this.props;
    const {rivalsOrder, activeGroupId = 0, collapsedGroups = []} = this.state;
    const featureFlag = [['defaults', 'dashboard'], {
      sortBy: rivalsOrder || 'cards',             // currently selected sort
      activeGroupId,      // current group filter: > 0 = valid groupId, 0 = all companies, -1 = all groups
      collapsedGroups
    }];

    this.context.api.userUpdate({id: user.id, featureFlag}).then(updatedUser => {
      console.log('Dashboard.handleSaveUserDefaults: userId #%o, featureFlag: %o, updatedUser: %o', user.id, featureFlag, updatedUser);
    });
  };

  handleSaveCompanyDefaults = () => {
    const {user} = this.props;
    const companyData = this._getCompanyData();

    if(!companyData || _.isEmpty(user)) {
      return;
    }

    const {rivalsOrder, activeGroupId} = this.state;
    const {utils: {dialog, featureFlagUpdate}} = this.context;
    const featureFlagPath = ['defaults', 'dashboard'];
    const featureFlagValue = {
      sortBy: rivalsOrder,             // currently selected sort
      activeGroupId,                   // current group filter: > 0 = valid groupId, 0 = all companies, -1 = all groups
      curatorId: user.id,              // audit trail
      updatedAt: moment().format()     // match ruby API's ISO8601 format
    };

    featureFlagUpdate(featureFlagPath, featureFlagValue, updatedCompanyData => {
      const {company} = this.props;

      dialog.alert(
        `<strong>Company defaults updated!</strong><br /><br />Your current dashboard sort and group display options
        have been saved as the defaults for all <strong>${company.name}</strong> users.`
      );

      console.log('Dashboard.handleSaveCompanyDefaults: companyData: %o, updatedCompany: %o', updatedCompanyData, company);
    });
  };

  handleGroupClick = groupId => {
    const {onDisplayChange, history} = this.props;

    const updateAction = () => {
      onDisplayChange().then(() => {
        this.handleSaveUserDefaults();
        this.sortRivals();

        history.push({pathname: '/dashboard', search: `?group=${groupId}`});
      });
    };

    if(groupId === -1) {
      return this.setState({activeGroupId: -1}, () => {
        console.log('Dashboard.handleGroupClick: showing all groups');

        updateAction();
      });
    }
    else if(groupId === 'default') {
      const companyData = this._getCompanyData();
      const defaultGroupId = dig(companyData, 'defaults.dashboard.activeGroupId', null);

      return this.handleGroupClick(defaultGroupId);
    }

    if(groupId > 0) {
      const activeGroup = this._findGroup(groupId) || this._getGroupTemplate();

      if(_.isEmpty(activeGroup)) {
        return;
      }

      this.setState({activeGroupId: activeGroup.id}, () => {
        console.log('Dashboard.handleGroupClick: selected groupId #%o: %o', groupId, activeGroup);

        updateAction();
      });
    }
    else {
      // clear group filter
      this.setState({activeGroupId: 0}, () => {
        console.log('Dashboard.handleGroupClick: cleared selected group');

        updateAction();
      });
    }
  };

  // NOTE:
  // - this is necessary as the top-level AppBase.rivals collection is stored as an unsorted hash
  // - the sorting (including secondary sorts) here must *exactly match* what the API returns or it will break the dashboard card
  //   visibility logic in DashboardView (i.e. re-render in mid-load due to a non-matching sort breaks the last page of results) (see #4089)
  // TODO: consider storing AppBase.rivals in a sorted Map to preserve original order from API and remove the need for this logic
  sortRivals = (props = this.props) => {
    const rivals = (props.rivals || []).slice();
    const {rivalsOrder} = this.state;

    if(!props.rivalGroups || !rivalsOrder) {
      // make sure we have a rivalsOrder set before display cards (load from user/company defaults, fall back to by # of cards)
      return;
    }

    const sortedRivals = rivals.sort((r1, r2) => {
      if(!r1.profile || !r2.profile) {
        // defensive response in case rivals are still mid-population
        return 0;
      }

      const r1IsInActiveGroup = this._isInActiveGroup(r1.id);
      const r2IsInActiveGroup = this._isInActiveGroup(r2.id);
      const r1CardsCount = r1.profile.cardsCount;
      const r2CardsCount = r2.profile.cardsCount;

      if((r1IsInActiveGroup || r2IsInActiveGroup) && (r1IsInActiveGroup !== r2IsInActiveGroup)) {
        return r1IsInActiveGroup ? -1 : 1;
      }

      switch(rivalsOrder) {
        case 'cards':
        default:
          if(r1CardsCount === r2CardsCount) {
            return r1.name.localeCompare(r2.name);
          }

          return r1CardsCount < r2CardsCount ? 1 : -1;

        case 'alpha':
          return r1.name.localeCompare(r2.name);

        case 'alpha-rev':
          return -r1.name.localeCompare(r2.name);

        case 'created':
          return new Date(r2.profile.createdAt) - new Date(r1.profile.createdAt);

        case 'created-rev':
          return new Date(r1.profile.createdAt) - new Date(r2.profile.createdAt);

        case 'battlecards':
          const r1Battlecard = this.context.utils.hasBattlecard(r1.profile.id);
          const r2Battlecard = this.context.utils.hasBattlecard(r2.profile.id);

          if(r1Battlecard === r2Battlecard) {
            // fall back to sort by battlecards count
            if(r1.profile.battlecardsCount === r2.profile.battlecardsCount) {
              // fall back to sort by cards count
              if(r1CardsCount === r2CardsCount) {
                return r1.name.localeCompare(r2.name);
              }

              return r1CardsCount < r2CardsCount ? 1 : -1;
            }

            return r1.profile.battlecardsCount < r2.profile.battlecardsCount ? 1 : -1;
          }

          return r1Battlecard ? -1 : 1;
      }
    });

    this.setState({sortedRivals}, console.log('Dashboard.sortRivals: sorted rivals by %o: %o', rivalsOrder, this.state.sortedRivals));
  };

  handleSortClick = rivalsOrder => {
    if(rivalsOrder === 'default') {
      const companyData = this._getCompanyData();
      const defaultSortBy = dig(companyData, 'defaults.dashboard.sortBy', null);

      if(defaultSortBy) {
        this.handleSortClick(defaultSortBy);

        return;
      }
    }

    this.setState({rivalsOrder}, () => {
      this.props.onDisplayChange().then(() => {
        this.handleSaveUserDefaults();
        this.sortRivals();
      });
    });
  };

  getSortedGroups = groups => {
    return groups.sort((a, b) => {
      const viewOrderA = parseFloat(a.viewOrder);
      const viewOrderB = parseFloat(b.viewOrder);

      return (viewOrderA === viewOrderB) ? a.name.toLowerCase().localeCompare(b.name.toLowerCase()) : (viewOrderA - viewOrderB);
    });
  };

  handleDashboardViewAs = (previewVisibilityGroup = null) => {
    const {appData: {v2Host}} = this.context;

    if(previewVisibilityGroup !== null) {
      const v2Search = `?previewing=${previewVisibilityGroup}`;

      return redirectToV2({v2Host, v2Path: window.location.pathname, v2Search, newTab: true});
    }
  };

  handleFilterChange = (event, value) => {
    this.setState({
      companyFilter: event ? event.target.value : value
    });
  };

  handleFilterCancel = event => {
    event = event || window.event;

    // cancel event can be mouse click (clear input) or keyboard event (escape)
    if(!event.which || (event.which === 27)) {
      this.clearFilter();
    }
  };

  handleCloseOverlayLoader = () => this.setState({waitingOnProfileId: null});

  handleViewBoard = () => {
    const {waitingOnProfileId} = this.state;
    const {history: {push} = {}} = this.props;

    if(!waitingOnProfileId || !push) {
      return;
    }

    push({
      pathname: `/profile/${waitingOnProfileId}/edit`,
      state: {fromViewInBoard: true}
    });
  };

  clearFilter = () => {
    const {companyFilter} = this.state;

    if(companyFilter) {
      this.setState({
        companyFilter: ''
      });
    }
  };

  setDashboardViewRef = ref => this.dashboardView = ref;

  render() {
    const {rivalGroups, editMode, user, totalRivals, onDisplayChange} = this.props;
    const {
      activeGroupId,
      activeEditGroupId,
      companyFilter,
      rivalsOrder,
      collapsedGroups,
      companyAddOpen,
      waitingOnProfileId,
      profileReadyStatus,
      sortedRivals,
      waitingOnRival,
      waitingOnOptions
    } = this.state;
    const {COMPLETED, FAILURE, IDLE} = profileStatus;
    const isCurator = userCanCurate({user});
    const rivals = (sortedRivals || []).slice();
    const groups = rivalGroups ? this.getSortedGroups(rivalGroups.slice()) : null;
    const activeGroup = this._getActiveGroup();
    const showGroupings = (activeGroupId === -1);
    const waitingOnProfile = profileReadyStatus === IDLE;
    const profileSuccess = profileReadyStatus === COMPLETED;
    const profileReady = profileSuccess || (profileReadyStatus === FAILURE);
    const haveWaitingProfileId = waitingOnProfileId !== null;
    const messages = overlayMessages({rival: waitingOnRival, options: waitingOnOptions});

    const dashboardClasses = classNames('dashboard', {
      'dashboard--editing': editMode
    });
    let filteredRivals = rivals.filter(r => Boolean(r.profile));
    let dashboardBody;

    if(!editMode && activeGroup) {
      const activeGroupRivalIds = (activeGroup.rivals || []).map(r => r.id);

      filteredRivals = rivals.filter(rival => activeGroupRivalIds.includes(rival.id));
    }

    if(companyFilter) {
      filteredRivals = filterRivals(rivals, companyFilter, this._getActiveGroup());
    }

    const sharedProps = {
      user,
      companyFilter
    };

    const viewToolbarProps = {
      rivals: filteredRivals,
      rivalGroups: groups,
      activeGroup,
      showGroupings
    };

    const editToolbarProps = {
      unfilteredRivals: rivals,
      onFilterChange: this.handleFilterChange,
      onFilterCancel: this.handleFilterCancel
    };

    const viewEditProps = {
      rivalsOrder,
      totalRivals,
      rivalGroupsLoaded: rivalGroups !== null,
      onToggleGroupMembership: this.handleToggleGroupMembership
    };

    if(editMode && isCurator) {
      dashboardBody = (
        <DashboardEdit
          {...sharedProps}
          {...viewEditProps}
          {...editToolbarProps}
          rivalGroups={(groups || []).filter(g => Boolean(g.id))}     // exclude "ungrouped companies" from edit mode
          activeEditGroupId={activeEditGroupId}
          onSetActiveEditGroupId={this.setActiveEditGroupId}
          onError={this.handleDashboardError} />
      );
    }
    else {
      dashboardBody = (
        <DashboardView
          {...sharedProps}
          {...viewEditProps}
          {...viewToolbarProps}
          activeGroupId={activeGroupId}
          rivalsLoaded={this.props.rivals !== null}
          collapsedGroups={collapsedGroups}
          onToggleGroup={this.handleToggleGroup}
          onDisplayChange={onDisplayChange} />
      );
    }

    return (
      <div className={dashboardClasses}>
        <OverlayLoader
          message={(profileReady && !profileSuccess) ? '<h1>Whoops! An error occurred while building your board.</h1><p>Some of your card data may be incomplete.</p><p>Please contact support if you need help.</p>' : null}
          scrollingMessages={!profileReady ? messages : []}
          showSpinner={waitingOnProfile}
          visible={haveWaitingProfileId}
          onClose={this.handleCloseOverlayLoader}
          isReadyAndSuccess={profileReady && profileSuccess}>
          {profileReady && (
            profileSuccess
              ?
              (<OverlayLoaderBoardReadyButtons
                onClick={this.handleViewBoard}
                title="Your board is ready"
                subTitle={`Your <b>${waitingOnRival.name}</b> board is ready to be viewed.`}
                buttonStyle="default"
                onClose={this.handleCloseOverlayLoader}
                buttonLabel="View Board" />)
              :
              (<OverlayLoaderBoardReadyButtons
                onClick={this.handleCloseOverlayLoader}
                title="Something went wrong. Please try again."
                buttonLabel="Close"
                buttonStyle="failure" />)
          )}
        </OverlayLoader>
        <DashboardToolbar
          {...sharedProps}
          {...viewToolbarProps}
          {...editToolbarProps}
          editMode={editMode}
          sortBy={rivalsOrder}
          companyAddOpen={companyAddOpen}
          onGroupClick={this.handleGroupClick}
          onGroupEditClick={this.handleGroupEditClick}
          onSortClick={this.handleSortClick}
          onCreateBoard={this.handleCreateBoard}
          onCompanyAddClick={this.handleAddRivalClick}
          onGetCompanyData={this._getCompanyData}
          onSaveCompanyDefaults={this.handleSaveCompanyDefaults}
          onToggleCompanyAddClick={this.onToggleCompanyAddClick}
          onViewAs={this.handleDashboardViewAs} />
        <div className="dashboard-page" ref={this.setDashboardViewRef}>
          {dashboardBody}
        </div>
        <ReactTooltip
          class="tooltip"
          html={true}
          effect="solid" />
      </div>
    );
  }

}

const mapStateToProps = ({onboard}) => ({
  __onboardBCActive: onboard.onboardBC.active
});

export default withRouter(connect(mapStateToProps)(Dashboard));

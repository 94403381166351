import {useEffect, useState} from 'react';

import LoginBox from './_login_box';
import LoginEmbed from './_login_embed';
import {resizeExtension, klueIsInExtension, sendMessageToExtensionBackground} from '../modules/ext_utils';
import {clearAuthSuccessful, isAuthSuccessful} from '../modules/user_utils';
import {klueIsInIframe} from '../modules/route_utils';

import '../../images/logo-extension.svg';
import '../../images/klue-logo-black.svg';
import mastImagePath from '../../images/klue-aiw.svg';
import blogIconPath from '../../images/icon-blog.svg';
import {klueIsInTeams} from '../hooks/useMicrosoftTeams';

// Check if this embedded frame has been authenticated
//   (ie, user has signed in elsewhere)
// Since we cannot read the content of http-only cookies,
//   we must do this by making a request to a secured endpoint, and check the status code.
async function serverCheckIfAuthenticated() {
  const {status} = await fetch('/api/users/me.json').catch(console.warn);

  return Boolean(status === 200);
}

const Login = props => {
  const {
    csrfToken,
    email,
    flashMessages,
    forgotPassPostUrl,
    klueUtk,
    logoPath,
    signInPostUrl,
    src
  } = props;
  const [authCheckInterval, setAuthCheckInterval] = useState(undefined);
  const inExtension = klueIsInExtension(src);
  const inTeams = klueIsInTeams(src);

  useEffect(() => {
    sendMessageToExtensionBackground({type: 'klue:app:logout'}).catch(console.warn);

    // Check if content is in iframe or not
    if(klueIsInIframe()) {
      // If in an iframe, constantly poll to determine if the user has become authenticated
      // reloading the page if they do.
      setAuthCheckInterval(
        setInterval(async () => {
          const locallyAuthenticated = isAuthSuccessful();

          if(locallyAuthenticated) {
            // locallyAuthenticated might still true if the user is signed out elsewhere (ex, on another tab), and the tab is refreshed
            // so, check with the server first to avoid an infinite reload loop
            const serverAuthenticated = await serverCheckIfAuthenticated();

            if(!serverAuthenticated) {
              clearAuthSuccessful();
            }
            else {
              window.location.reload();
            }
          }
        }, 1000)
      );
    }

    return () => {
      clearInterval(authCheckInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(inExtension && klueIsInIframe()) {
      document.body.id = 'klue-extension';
      resizeExtension();
    }
  }, [inExtension]);

  const uiLoginBox = (
    <LoginBox
      forgotPassPostUrl={forgotPassPostUrl}
      signInPostUrl={signInPostUrl}
      csrfToken={csrfToken}
      flashMessages={flashMessages}
      email={email}
      klueUtk={klueUtk}
      inTeams={inTeams}
      onElementResize={inExtension ? resizeExtension : null} />
  );

  const uiFooter = (
    <div className="layout-login-footer">
      <div className="layout-login-footer_social">
        <ul className="login-social-buttons">
          <li>
            <a href="https://twitter.com/@kluein">
              <i className="fa fa-twitter" />
            </a>
          </li>
          <li>
            <a href="https://facebook.com/kluein">
              <i className="fa fa-facebook" />
            </a>
          </li>
          <li>
            <a href="//klue.com/blog/">
              <img alt="Klue Blog" src={blogIconPath} />
            </a>
          </li>
          <li>
            <a href="https://angel.co/klue">
              <i className="fa fa-angellist" />
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/klue">
              <i className="fa fa-linkedin" />
            </a>
          </li>
        </ul>
        <div className="layout-login-footer_legal">
          <div className="login-legal-links">
            <a href="/acceptable-use">Acceptable Use Policy</a> |
            <a href="/privacy">Privacy</a> |
            <a href="/terms">Terms &amp; Conditions</a>
          </div>
        </div>
      </div>
    </div>
  );

  if(klueIsInIframe()) {
    return <LoginEmbed logoPath={logoPath} />;
  }

  return (
    <div className="layout-login" data-testid="login">
      <div className="layout-login_mast">
        <div className="layout-login_mast_body">
          <img alt="Klue All-in-Won" src={mastImagePath} width="409" height="188" />
          <h2>
            Combine market, competitor, and buyer insights in a unified
            platform, driven by AI. Find the way to win.
          </h2>
        </div>

      </div>
      {/* masthead image set via CSS */}
      <div className="layout-login_panel">
        <div className="layout-login_panel_body layout-login_panel_body--grow layout-login_panel_body--vpad">
          <div className="layout-login_panel_body_vertical">
            <div className="layout-login-contain layout-login-contain--logo">
              <img alt="Klue" src={logoPath} />
            </div>
            {uiLoginBox}
          </div>
        </div>
        <div className="layout-login_panel_body">{uiFooter}</div>
      </div>
    </div>
  );
};

Login.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  email: PropTypes.string,
  flashMessages: PropTypes.array,
  forgotPassPostUrl: PropTypes.string.isRequired,
  klueUtk: PropTypes.string,
  logoPath: PropTypes.string.isRequired,
  signInPostUrl: PropTypes.string.isRequired,
  src: PropTypes.string
};

Login.defaultProps = {
  email: '',
  flashMessages: [],
  klueUtk: null,
  src: undefined
};

export default Login;

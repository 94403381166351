import {fetch, post} from '../api_utils';
import {isValidId, serializeQueryString} from '../utils';
import {RIVALS_PER_PAGE, RIVALS_LIMIT_UNLIMITED} from '../constants/api';

// rival management API

export const rivalsGet = ({rivalOptions = {page: 1, limit: RIVALS_PER_PAGE}, code = 'ApiUtils.rivalsGet'}) => {
  // options format:
  //   rivalOptions: {
  //     page: number,                // 1-based
  //     limit: number,               // page size
  //     order: string,               // alpha, alpha-rev, etc,
  //     recent: non-null,            // optional; retrieves rivals matching current user's recently viewed profiles
  //     rivalIds: int[],             // optional; array of integers, retrieve a specific set of rivals
  //     profileIds: int[],           // optional; array of integers, retrieve a specific set of profiles
  //     rivalGroupId: int,           // optional; restrict results to associated rival group id; set to 0 to retrieve all ungrouped rivals
  //     query: string                // optional, restricts results by case-insensitive search on rival name
  //   }
  return new Promise((resolve, reject) => {
    if(_.isEmpty(rivalOptions)) {
      console.warn(`${code}: invalid options specified: %o`, rivalOptions);

      return reject(rivalOptions);
    }

    const {page, limit, order, recent, rivalIds, profileIds, rivalGroupId, query} = rivalOptions;
    const urlParams = serializeQueryString({
      page,
      limit,
      order,
      recent,
      rivalIds,
      profileIds,
      rivalGroupId,
      query
    });

    fetch(`/api/rivals.json?v=3${urlParams ? `&${urlParams}` : ''}`, {code})
      .then(({data: {totalItems: totalRivals, items: rivals}}) => {
        console.log(`${code}: rivals loaded: %o, total: %o`, rivals, totalRivals);

        resolve({rivals, totalRivals});
      })
      .catch(error => {
        console.error(code, error);

        reject(error);
      });
  });
};

// return an unpaginated, shallow listing of rivals (for use in alerts and profile rival listings)
export const rivalsList = ({rivalOptions = {}, code = 'ApiUtils.rivalsList'}) => {
  // options format:
  //   rivalOptions: {
  //     curators: non-null,          // optional; include profile curator ids
  //     navMode: non-null,           // optional; include additional fields required by navigation bar (icon, cardsCount)
  //     order: string,               // alpha, alpha-rev, etc,
  //     recent: non-null,            // optional; retrieves rivals matching current user's recently viewed profiles
  //     rivalGroupId: int,           // optional; restrict results to associated rival group id; set to 0 to retrieve all ungrouped rivals
  //     query: string                // optional, restricts results by case-insensitive search on rival name
  //   }
  return new Promise((resolve, reject) => {
    const {curators, navMode, order, recent, rivalGroupId, query} = rivalOptions;
    const urlParams = serializeQueryString({
      page: 1,                        // TODO: pass in pagination params from callee
      limit: RIVALS_LIMIT_UNLIMITED,  // TODO: pass in pagination params from callee
      curators,
      icon: navMode,
      cardsCount: navMode,
      order,
      recent,
      rivalGroupId,
      query
    });

    fetch(`/api/rivals.json?minimal=1${urlParams ? `&${urlParams}` : ''}`, {code})
      .then(({data: {totalItems: totalRivals, items: rivals}}) => {
        console.log(`${code}: rivals listing loaded: %o, total: %o`, rivals, totalRivals);

        resolve(rivals);
      })
      .catch(error => {
        console.error(code, error);

        reject([]);
      });
  });
};

export const rivalGet = (rivalOptions = {rivalId: 0, profileId: 0, name: ''}, code = 'ApiUtils.rivalGet') => {
  // options format:
  //   rivalOptions: {
  //     rivalId: int,
  //     profileId: int,              // optional; required if rivalId or name not specified
  //     name: string,                // optional; required if rivalId or profileId not specified,
  //     minimal: non-null            // optional; return just the most essential fields
  //   }
  const {rivalId, profileId, name, minimal} = rivalOptions;
  const flags = {minimal};

  return new Promise((resolve, reject) => {
    if(_.isEmpty(rivalOptions) || (!isValidId(rivalId) && !isValidId(profileId) && !name)) {
      console.warn(`${code}: invalid options specified: rivalOptions: %o`, rivalOptions);

      return reject(rivalOptions);
    }

    let urlParams;

    if(!rivalId) {
      urlParams = serializeQueryString({name, ...flags});
    }
    else {
      urlParams = serializeQueryString({id: rivalId, ...flags});
    }

    let url;

    if(isValidId(profileId)) {
      url = `/api/profiles/${profileId}/rival.json`;
    }
    else {
      url = '/api/rivals/show.json';
    }

    fetch(`${url}${urlParams ? `?${urlParams}` : ''}`, {code})
      .then(({data: rival}) => {
        if(_.isEmpty(rival)) {
          console.error(`${code}: invalid rival found for rivalId #%o, profileId #%o, name: %o`, rivalId, profileId, name);

          return reject(null);
        }

        console.log(`${code}: loaded rival for rivalId #%o, profileId #%o, name: %o: %o`, rivalId, profileId, name, rival);

        resolve(rival);
      })
      .catch(error => {
        console.error(`${code}: no rival found for rivalId #%o, profileId #%o, name: %o, error: %o`, rivalId, profileId, name, error);

        reject(error);
      });
  });
};

export const rivalCreate = ({rivalOptions, code = 'ApiUtils.rivalCreate'}) => {
  // options format:
  //    rivalOptions: {
  //      name: string,               // required
  //      url: string,                // required for company and product boards
  //      category: string,           //['other', 'company', 'product', 'industry', 'topic']
  //      withMetabaseSubscription: bool,
  //      imageUrl: string,
  //      copyFromId: string,
  //      curators: string[],
  //      rivalGroups: string[],
  //      automatedCards: string[],
  //      isTemplate: bool
  //   }
  const {name, url, category} = rivalOptions;

  return new Promise((resolve, reject) => {
    if(_.isEmpty(rivalOptions) || !name || (['company', 'product'].includes(category) && !url)) {
      console.warn(`${code}: invalid options specified: %o`, rivalOptions);

      return reject(rivalOptions);
    }

    post('/api/rivals.json', JSON.stringify(rivalOptions), {code})
      .then(({data: rival}) => {
        if(_.isEmpty(rival)) {
          console.error(`${code}: failed to create rival: %o`, rival);

          return reject();
        }

        console.log(`${code}: created rival successfully: %o`, rival);
        resolve(rival);
      })
      .catch(error => {
        console.error(code, error);

        reject(error);
      });
  });
};

import DashboardEditRivals from './_dashboard_edit_rivals';
import DashboardEditGroups from './_dashboard_edit_groups';

// layout components
import Page from './layout/_page';

import {rivalsList} from '../modules/api/rivals';
import {filterRivals} from '../modules/rival_utils';
import {userCanCurate} from '../modules/roles_utils';

import {compose} from 'redux';
import {withRouter} from 'react-router-dom';

class DashboardEdit extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    history: PropTypes.object,
    user: PropTypes.object,
    rivalGroups: PropTypes.arrayOf(PropTypes.object),
    totalRivals: PropTypes.number,
    activeEditGroupId: PropTypes.number,
    rivalsOrder: PropTypes.string,
    rivalGroupsLoaded: PropTypes.bool.isRequired,
    onSetActiveEditGroupId: PropTypes.func.isRequired,
    onToggleGroupMembership: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired
  };

  static defaultProps = {
    history: {},
    user: null,
    rivalGroups: [],
    totalRivals: 0,
    activeEditGroupId: 0,
    rivalsOrder: '',        // empty string if user/company defaults haven't been loaded yet
    rivalGroupsLoaded: false,
    onSetActiveEditGroupId() {},
    onToggleGroupMembership() {},
    onError() {}
  };

  state = {
    rivals: null,             // null = not finished loading; [] = no rivals found for company
    unfilteredRivals: [],
    activeRivalId: 0,
    editGroupId: 0,           // represents actual group being edited (row in edit mode) - not toggled open
    companyFilter: '',
    highlightedGroups: [],
    showAddGroup: false,
    groupDragging: false,
    groupPositions: []
  };

  componentDidMount() {
    // DEBUG
    console.log('DashboardEdit.componentDidMount: props: %o', this.props);

    const {history, user, rivalGroups, rivalsOrder} = this.props;

    if(!userCanCurate({user})) {
      history.push('/');
    }

    if(rivalGroups) {
      this.setState({
        groupPositions: rivalGroups.map(g => g.id)
      });
    }

    if(rivalsOrder) {
      this.loadRivals(rivalsOrder);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {rivalGroups, rivalsOrder, totalRivals} = this.props;
    const {rivalGroups: nextGroups, rivalsOrder: nextRivalsOrder, totalRivals: nextTotalRivals} = nextProps;

    if(nextRivalsOrder === '') {
      // props/parent state isn't fully initialized yet, skip rivals/placeholders load
      return;
    }

    if((rivalsOrder !== nextRivalsOrder) || (Math.abs(nextTotalRivals - totalRivals) === 1)) {
      this.loadRivals(nextRivalsOrder);
    }

    if(nextGroups && (
      (!rivalGroups && nextGroups.length) ||
      (rivalGroups && (rivalGroups.length !== nextGroups.length))
    )) {
      this.setState({
        groupPositions: nextGroups.slice().map(g => g.id)
      }, () => {
        // DEBUG
        console.log('DashboardEdit.componentWillReceiveProps: updated group positions: %o', this.state.groupPositions);
      });
    }
  }

  _preventDefault = e => e && e.preventDefault();

  _stopPropagation = e => {
    if(e) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  _getActiveGroup = () => {
    const rivalGroups = (this.props.rivalGroups || []).slice();
    const {activeEditGroupId} = this.props;

    if(!rivalGroups.length || !activeEditGroupId) {
      return false;
    }

    return rivalGroups.find(g => g.id === activeEditGroupId);
  };

  _isInActiveGroup = rivalId => {
    const activeGroup = this._getActiveGroup();

    if(!activeGroup) {
      return false;
    }

    for(let i = 0; i < activeGroup.rivals.length; i++) {
      if(activeGroup.rivals[i].id === rivalId) {
        return true;
      }
    }

    return false;
  };

  loadRivals = order => {
    const rivalOptions = {
      navMode: true,
      order
    };

    // TODO: potentially paginate this listing in the future (would need to load on scroll/group select)
    rivalsList({rivalOptions, code: 'DashboardEdit.loadRivals'}).then(rivals => {
      this.setState({
        unfilteredRivals: rivals,
        rivals: filterRivals(rivals, this.state.companyFilter, this._getActiveGroup())
      });
    });
  };

  handleFilterChange = (event, value) => this.setState({companyFilter: event ? event.target.value : value});

  clearFilter = () => {
    const {companyFilter} = this.state;

    if(companyFilter) {
      this.setState({
        companyFilter: ''
      });
    }
  };

  handleFilterCancel = event => {
    event = event || window.event;

    // cancel event can be mouse click (clear input) or keyboard event (escape)
    if(!event.which || (event.which === 27)) {
      this.clearFilter();
    }
  };

  handleCompanyClick = (rivalId = 0, event) => {
    this._preventDefault(event);

    const {activeRivalId} = this.state;
    const {activeEditGroupId, onToggleGroupMembership} = this.props;

    if(!rivalId || (rivalId <= 0)) {
      // rival unset, do nothing
      return;
    }
    else if(rivalId === activeRivalId) {
      // rival already active, un-toggle`
      this.setState({
        activeRivalId: 0
      });

      return;
    }

    this.setState({
      activeRivalId: rivalId
    }, () => {
      // DEBUG
      console.log('DashboardEdit.handleCompanyClick: set active rivalId: %o', activeRivalId);

      if(activeEditGroupId) {
        // add company to currently expanded group (if not already in group)
        onToggleGroupMembership({rivalId: this.state.activeRivalId, groupId: activeEditGroupId}, () => {
          // reset active rival after add/remove current group
          this.setState({
            activeRivalId: 0
          });
        });
      }
    });
  };

  handleCompanyHover = (rivalId = 0, isHovered = true) => {
    const rivalGroups = (this.props.rivalGroups || []).slice();

    if(!rivalGroups || !rivalGroups.length) {
      return;
    }

    if(!isHovered) {
      this.setState({
        highlightedGroups: []
      });

      return;
    }

    const highlightedGroups = [];

    rivalGroups.map(group => {
      const rivals = group.rivals || [];

      if(rivals.length && rivals.find(r => r.id === rivalId) && !highlightedGroups.includes(group.id)) {
        highlightedGroups.push(group.id);
      }
    });

    this.setState({highlightedGroups});
  };

  handleToggleGroupClick = (group = null, callback = null) => {
    if(_.isEmpty(group) || (group.id <= 0)) {
      return;
    }

    const {activeRivalId} = this.state;
    const {activeEditGroupId, onToggleGroupMembership, onSetActiveEditGroupId} = this.props;
    const isActiveGroup = (group.id === activeEditGroupId);

    if(activeRivalId && (!isActiveGroup || !this._isInActiveGroup(activeRivalId))) {
      onToggleGroupMembership({rivalId: activeRivalId, groupId: group.id}, () => {
        // reset active rival after add/remove current group
        this.setState({
          activeRivalId: 0
        });
      });
    }

    onSetActiveEditGroupId(isActiveGroup ? 0 : group.id);

    return typeof callback === 'function' && callback();
  };

  handleEditGroupClick = (group, event) => {
    this._stopPropagation(event);

    if(_.isEmpty(group) || (group.id <= 0)) {
      return this.setState({editGroupId: 0});
    }

    if(this.state.showAddGroup) {
      this.setState({showAddGroup: false});
    }

    this.setState({editGroupId: group.id});
  };

  handleReorderGroupDrag = (isDragging = true) => {
    // NOTE: this triggers the dashboard-group-list--dragging flag to work around an unfixed dnd/hover-related chromium bug:
    // https://bugs.chromium.org/p/chromium/issues/detail?id=410328
    // further reading: https://github.com/react-dnd/react-dnd/issues/476
    if(this.state.groupDragging !== isDragging) {
      this.setState({
        groupDragging: isDragging
      });
    }
  };

  handleReorderGroupMove = (dragItemProps, targetItemProps) => {
    const groupPositions = (this.state.groupPositions || []).slice();
    const fromIndex = dragItemProps.droppableCardIndex;
    const toIndex = targetItemProps.droppableCardIndex;
    const draggedGroupId = groupPositions[fromIndex];

    if(fromIndex === toIndex) {
      return;
    }

    this.setState({
      groupPositions: ReactUpdate(this.state.groupPositions, {
        $splice: [
          [fromIndex, 1],
          [toIndex, 0, draggedGroupId]
        ]
      }, () => {
        console.log('DashboardEdit.handleReorderGroupHover: fromIndex: %o, toIndex: %o, moved group %o', fromIndex, toIndex, draggedGroupId);
      })
    });

    dragItemProps.droppableCardIndex = targetItemProps.droppableCardIndex;
  };

  handleReorderGroupDrop = () => {
    const groupPositions = (this.state.groupPositions || []).slice();

    if(!groupPositions || !groupPositions.length) {
      return;
    }

    // save *all* groups based on new viewOrders
    // NOTE: this blindly reorders by updated groupPositions to avoid any sync issues with props.rivalGroups state updates from above
    // TODO: add batched API update keeping all viewOrders in sync and avoiding problems with "only changed" viewOrder updates here
    groupPositions.forEach((groupId, index) => {
      const rivalGroupOptions = {
        id: groupId,
        viewOrder: parseFloat(index)
      };

      this.context.api.rivalGroupUpdate(rivalGroupOptions, (updatedGroup, errorText) => {
        if(errorText) {
          this.props.onError({
            title: 'Unable to save group!',
            message: `Sorry, we encountered an error when reordering your groups.
              Please refresh the page and try again.<br /><br /><em>Error: ${errorText}</em>`
          });

          return;
        }

        // DEBUG
        console.log('DashboardEdit.handleReorderGroupDrop: updated groupId #%o: %o, new viewOrder: %o', updatedGroup.id, updatedGroup, updatedGroup.viewOrder);
      });
    });
  };

  handleSaveGroupClick = (group = null, name = '', event) => {
    this._stopPropagation(event);

    if(_.isEmpty(group) || (group.id <= 0) || (this.state.editGroupId !== group.id) || !name) {
      if(!name) {
        this.props.onError({
          title: 'We couldn\'t save your group!',
          message: 'Please enter a name for your group.'
        });
      }

      return;
    }

    const rivalGroups = (this.props.rivalGroups || []).slice();

    if(rivalGroups.find(g => (g.id !== group.id) && (g.name.toLowerCase() === name.toLowerCase()))) {
      this.props.onError({
        title: '🚨 We couldn\'t update your group!',
        message: 'Sorry, it looks like a group with that name already exists.'
      });

      return;
    }

    const rivalGroupOptions = {
      id: group.id,
      name
    };

    this.context.api.rivalGroupUpdate(rivalGroupOptions, (updatedGroup, errorText) => {
      if(errorText) {
        this.props.onError({
          title: '🚨 Unable to save group!',
          message: `Sorry, we encountered an error when saving your group.<br /><br /><em>Error: ${errorText}</em>`
        });

        return;
      }

      // DEBUG
      console.log('DashboardEdit.handleSaveGroupClick: updated groupId #%o: %o', updatedGroup.id, updatedGroup);

      // clear edited rival
      this.setState({editGroupId: 0});
    });
  };

  handleDeleteGroupClick = (group, event) => {
    this._stopPropagation(event);

    if(_.isEmpty(group) || (group.id <= 0)) {
      return;
    }

    const deleteAction = () => {
      // delete existing rival group
      this.context.api.rivalGroupDelete({id: group.id}, errorText => {
        if(errorText) {
          this.props.onError({
            title: '🚨 Unable to delete group!',
            message: `Sorry, we encountered an error when deleting your group.<br /><br /><em>Error: ${errorText}</em>`
          });

          return;
        }

        // DEBUG
        console.log('DashboardEdit.handleDeleteGroupClick: groupId #%o deleted successfully: %o', group.id, group);

        if(group.id === this.props.activeEditGroupId) {
          this.props.onSetActiveEditGroupId(0);
        }
      });
    };

    this.context.utils.dialog.confirm({
      message: `<strong>Delete group &quot;${group.name}&quot;</strong><br /><br />
        Are you sure you want to delete this group?`,
      okCallback: deleteAction
    });
  };

  handleToggleAddGroupForm = (enabled = null, event) => {
    this._preventDefault(event);

    if(this.state.editGroupId) {
      this.handleEditGroupClick(null);
    }

    this.setState(prevState => ({
      showAddGroup: (enabled !== null) ? enabled : !prevState.showAddGroup
    }));
  };

  handleAddRivalGroupClick = (groupName, callback = null) => {
    const rivalGroups = (this.props.rivalGroups || []).slice();
    const {onError} = this.props;

    if(!groupName) {
      onError({
        title: '🚨 We couldn\'t add your group!',
        message: 'Please enter a name for your group.'
      });

      return;
    }
    else if(rivalGroups.find(g => g.name.toLowerCase() === groupName.toLowerCase())) {
      onError({
        title: '🚨 We couldn\'t add your group!',
        message: 'Sorry, it looks like a group with that name already exists.'
      });

      return;
    }

    const rivalGroupOptions = {
      name: groupName
    };

    this.context.api.rivalGroupCreate(rivalGroupOptions, (rivalGroup, errorText) => {
      if(errorText) {
        onError({
          title: '🚨 Unable to create group!',
          message: `Sorry, we encountered an error when creating your group<br /><br /><em>Error: ${errorText}</em>`
        });
      }

      // DEBUG
      console.log('DashboardEdit.handleAddRivalGroupClick: created new rival group: %o', rivalGroup);

      this.handleToggleAddGroupForm(false);

      return typeof callback === 'function' && callback(Boolean(errorText));
    });
  };

  render() {
    const {user, rivalGroups, rivalGroupsLoaded, activeEditGroupId, onToggleGroupMembership} = this.props;
    const {rivals, unfilteredRivals, activeRivalId, editGroupId, companyFilter, showAddGroup, groupDragging, groupPositions, highlightedGroups} = this.state;

    return (
      <Page columns={2}>
        <DashboardEditRivals
          user={user}
          rivals={rivals}
          rivalsLoaded={Boolean(rivals)}
          companyFilter={companyFilter}
          activeRivalId={activeRivalId}
          activeEditGroupId={activeEditGroupId}
          checkIsInActiveGroup={this._isInActiveGroup}
          onFilterChange={this.handleFilterChange}
          onFilterCancel={this.handleFilterCancel}
          onCompanyHover={this.handleCompanyHover}
          onCompanyClick={this.handleCompanyClick} />
        <DashboardEditGroups
          rivals={rivals}
          rivalGroups={rivalGroups}
          unfilteredRivals={unfilteredRivals}
          activeEditGroupId={activeEditGroupId}
          editGroupId={editGroupId}
          showAddGroup={showAddGroup}
          groupDragging={groupDragging}
          groupPositions={groupPositions}
          highlightedGroups={highlightedGroups}
          isLoaded={Boolean(rivals) && rivalGroupsLoaded}
          onEditGroupClick={this.handleEditGroupClick}
          onSaveGroupClick={this.handleSaveGroupClick}
          onDeleteGroupClick={this.handleDeleteGroupClick}
          onReorderGroupDrag={this.handleReorderGroupDrag}
          onReorderGroupDrop={this.handleReorderGroupDrop}
          onReorderGroupMove={this.handleReorderGroupMove}
          onToggleGroupClick={this.handleToggleGroupClick}
          onToggleGroupMembership={onToggleGroupMembership}
          onToggleAddGroupForm={this.handleToggleAddGroupForm}
          onAddRivalGroupClick={this.handleAddRivalGroupClick} />
      </Page>
    );
  }

}

export default compose(
  withRouter
)(DashboardEdit);

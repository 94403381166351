import {Fragment} from 'react';
import {Form} from 'react-final-form';
import {FORM_ERROR} from 'final-form';

import {SUBMISSION_FAILED_MESSAGE} from '../../modules/constants/new_board_wizard';

class Wizard extends React.PureComponent {

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    nextValues: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    children: PropTypes.node
  };

  static defaultProps = {
    nextValues: {},
    initialValues: {},
    children: null
  };

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      values: props.initialValues || {}
    };
  }

  next = ({event, values, nextValues}) => {
    event.preventDefault();
    event.persist();
    this.setState(state => ({
      page: Math.min(state.page + 1, this.props.children.length - 1),
      values: {...values, ...nextValues}
    }));
  };

  previous = event => {
    event.preventDefault();
    event.persist();

    const {page} = this.state;

    if(page === 0) {
      this.props.onClose();
    }

    this.setState(state => ({page: Math.max(state.page - 1, 0)}));
  };

  validate = values => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ];

    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  handleSubmit = values => {
    const {onSubmit} = this.props;
    const {boardName, companyUrl, boardType, logoUrl, template, automatedCards: cards, groups} = values;

    const {profileId: copyFromId} = (template || {});
    const rivalGroupsIds = Array.isArray(groups) ? groups.map(group => group.id) : null;
    const automatedCardIds = ((boardType?.id.toLowerCase() !== 'other') && Array.isArray(cards)) ? cards.map(card => card.id) : [];

    const rivalOptions = {
      name: boardName,
      url: companyUrl,
      category: boardType?.id.toLowerCase(),
      withMetabaseSubscription: true,
      imageUrl: logoUrl
    };

    if(rivalGroupsIds) {
      rivalOptions.rivalGroups = rivalGroupsIds;
    }

    if(automatedCardIds) {
      rivalOptions.automatedCards = automatedCardIds;
    }

    if(copyFromId) {
      rivalOptions.copyFromId = copyFromId;
    }

    return onSubmit(rivalOptions)
      .catch(() => {
        //TODO: we could parse the BE error here and show a more accurate error
        return {[FORM_ERROR]: SUBMISSION_FAILED_MESSAGE};
      });
  };

  render() {
    const {children, onClose, nextValues} = this.props;
    const {page, values} = this.state;
    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === React.Children.count(children) - 1;

    return (

      <Form
        initialValues={values}
        validate={this.validate}
        onSubmit={this.handleSubmit}>
        {({handleSubmit, values: formValues, errors, submitting, form}) => {
          const hasError = Object.keys(errors).length !== 0;

          return (
            <form onSubmit={handleSubmit} className="wizard">
              {activePage}
              <div className="wizard-navigation">
                {
                  !isLastPage ?
                    (formValues.boardType && formValues.boardName) || formValues.boardType?.id === 'other'
                      ? <Fragment>
                        <button
                          className="btn btn-success wizard-navigation_button"
                          disabled={hasError}
                          type="submit"
                          onClick={event => this.next({event, values: formValues, nextValues})}>
                          Continue to Add Cards
                        </button>
                        <button
                          className="btn wizard-navigation_button wizard-navigation_button--secondary"
                          type="button"
                          disabled={hasError || submitting}
                          onClick={() => form.submit()}>
                          <b>CREATE NEW BOARD NOW!</b>
                        </button>
                      </Fragment>
                      : <Fragment>
                        <button
                          className="btn wizard-navigation_button wizard-navigation_button--cancel"
                          type="button"
                          onClick={event => {
                            event.preventDefault();
                            event.persist();
                            onClose();
                          }}>
                          <b>CANCEL</b>
                        </button>
                      </Fragment>

                    : <Fragment>
                      <button
                        className="btn btn-success wizard-navigation_button"
                        type="submit"
                        disabled={hasError || submitting}>
                        Save & View!
                      </button>
                      <button
                        className="btn wizard-navigation_button wizard-navigation_button--secondary"
                        type="button"
                        onClick={this.previous}>
                        <b>Go Back</b>
                      </button>
                    </Fragment>
                }
              </div>
            </form>
          );
        }}
      </Form>
    );
  }

}
export default Wizard;

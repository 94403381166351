import React from 'react';
import PropTypes from 'prop-types';

import GenericSeries from './_generic_series_graph';
import DistributionGraph from './_distribution_graph';
import EntityList from './_entity_list';
import JobPosting from './_job_posting';
import KeywordStatsTable from './_keyword_stats_table';
import MapOfPlaces from './_map_of_places';
import MetricTimeseriesGraph from './_metric_timeseries_graph';
import OnlineArticle from './_online_article';
import Person from './_person';
import RegionalEntities from './_regional_entities';
import SummaryTable from './_summary_table';
import Types from '../../../modules/types';
import WebsiteList from './_website_list';
import * as KlueTypes from '../../../modules/klue_types';
import Table from '../../_table';
import NoDataMessage from '../../_no_data_message';
import {sanitizeInput, allowlistedTags, allowlistedAttributes, allowlistedSchemes, wrapHtml} from '../../../modules/html_utils';
import {abbreviate} from '../../../modules/number_utils';

const KlueTypeComponentFactory = props => {
  const {val, cardId, cardCreatedAt = '', isDraft = false, analyticsEventContext = {}} = props;

  if(KlueTypes.Company.validate(val)) {
    const {info} = val;

    return (<p><strong>{info}</strong></p>);
  }

  if(Types.arrayOf(KlueTypes.Timeseries).validate(val)) {
    let haveData;
    const timeseries = val.map(inTimeseries => {
      const outTimeseries = inTimeseries.events.map(event => {
        const {date: {isoDate: date}, value} = event;

        return {date, value};
      });

      haveData = haveData || outTimeseries.length;

      return outTimeseries;
    });

    if(!haveData) {
      return (<NoDataMessage
        cardId={cardId}
        isDraft={isDraft}
        cardCreatedAt={cardCreatedAt}
        analyticsEventContext={analyticsEventContext} />);
    }

    const labels = val.map(ts => ts.label);

    // making an assumption that an array of timeseries equates to a normalized percentage comparison. Hence the labelInterpolationFnc param.
    // Fixes issue #6423
    return (<MetricTimeseriesGraph
      timeseries={timeseries}
      labelInterpolationFnc={label => `${abbreviate((label - 1) * 100, {integer: true})}%`}
      legend={labels} />);
  }

  if(Types.arrayOf(KlueTypes.KeywordStats).validate(val)) {
    return (<KeywordStatsTable keywordStats={val} />);
  }

  if(Types.arrayOf(KlueTypes.SummaryFact()).validate(val)) {
    return (<SummaryTable facts={val} />);
  }

  if(Types.arrayOf(KlueTypes.Website).validate(val)) {
    const domains = val.map(({websiteDomain}) => websiteDomain);

    return (<WebsiteList domains={domains} />);
  }

  if(Types.arrayOf(KlueTypes.GeoPlace).validate(val)) {
    return (<MapOfPlaces places={val} />);
  }

  if(KlueTypes.Matrix.validate(val)) {
    const {rows} = val;

    return (<Table rows={rows} />);
  }

  if(KlueTypes.Table.validate(val)) {
    const {records: {rows}, headers} = val;

    return (<Table rows={rows} headers={headers} />);
  }

  if(KlueTypes.Distribution.validate(val)) {
    const {portions} = val;

    return (<DistributionGraph portions={portions} />);
  }

  if(KlueTypes.GenericSeries.validate(val)) {
    const {data, type} = val;

    if(!data || !data.length) {
      return (<NoDataMessage
        cardId={cardId}
        cardCreatedAt={cardCreatedAt}
        isDraft={isDraft}
        analyticsEventContext={analyticsEventContext} />);
    }

    return (<GenericSeries data={data} type={type} />);
  }

  if(KlueTypes.Timeseries.validate(val)) {
    const {events, label} = val;

    if(!events.length) {
      return (<NoDataMessage
        cardId={cardId}
        cardCreatedAt={cardCreatedAt}
        isDraft={isDraft}
        analyticsEventContext={analyticsEventContext} />);
    }

    const timeseries = events.map(event => {
      const {date: {isoDate: date}, value} = event;

      return {date, value};
    });

    // If we have fewer than 2 data points then display as table instead of graph...
    if(events.length < 2) {
      const rows = timeseries.map(event => {
        return [event.date, event.value.toLocaleString('en-US', {
          style: 'decimal'
        })];
      });

      return (<Table rows={rows} />);
    }

    return (<MetricTimeseriesGraph timeseries={[timeseries]} label={label} />);
  }

  if(Types.arrayOf(Types.any).validate(val)) {
    return (<EntityList
      elements={val}
      cardCreatedAt={cardCreatedAt}
      cardId={cardId}
      isDraft={isDraft}
      analyticsEventContext={analyticsEventContext} />);
  }

  if(Types.mapOf(KlueTypes.City, Types.any).validate(val)) {
    const {pairs} = val;

    if(_.isEmpty(pairs)) {
      return (<NoDataMessage
        cardId={cardId}
        cardCreatedAt={cardCreatedAt}
        isDraft={isDraft}
        analyticsEventContext={analyticsEventContext} />);
    }

    return (<RegionalEntities
      entityMap={pairs}
      cardId={cardId}
      cardCreatedAt={cardCreatedAt}
      isDraft={isDraft}
      analyticsEventContext={analyticsEventContext} />);
  }

  if(KlueTypes.Image.validate(val)) {
    const {imageSrcUrl, alt} = val;

    return (
      <img
        src={imageSrcUrl}
        alt={alt}
        className="img-responsive dynamic-card-image"
        onError={err => console.warn('KlueTypeComponentFactory: image error:', err)} />
    );
  }

  if(KlueTypes.Person.validate(val)) {
    const {name, position, imageUrl, linkedinUrl, twitterUrl} = val;

    return (
      <Person
        name={name}
        position={position}
        imageUrl={imageUrl}
        linkedinUrl={linkedinUrl}
        twitterUrl={twitterUrl} />
    );
  }

  if(KlueTypes.JobPosting.validate(val)) {
    const {
      position,
      city: {
        city,
        state,
        country
      },
      posted: {
        isoDate: postedDate
      },
      source: {
        url: sourceUrl,
        title: sourceName
      }
    } = val;

    return (
      <JobPosting
        position={position}
        city={city}
        state={state}
        country={country}
        postedDate={postedDate}
        url={sourceUrl}
        source={sourceName} />
    );
  }

  if(KlueTypes.OnlineArticle.validate(val)) {
    const {link: {url, title}, headline, lead, published: {isoDate: publishedDate}} = val;

    return (
      <OnlineArticle
        url={url}
        source={title}
        headline={headline}
        lead={lead}
        publishedDate={publishedDate} />
    );
  }

  if(KlueTypes.HtmlContent.validate(val)) {
    const allowedTags = [...allowlistedTags, 'iframe'];
    const allowedAttributes = {
      ...allowlistedAttributes,
      iframe: ['allow', 'allowfullscreen', 'class', 'frameborder', 'height', 'id', 'src', 'width']
    };

    return (
      <div className="embed-html--container"
        dangerouslySetInnerHTML={wrapHtml(sanitizeInput(val.rawHtml, {
          allowedTags,
          allowedAttributes,
          allowedSchemes: allowlistedSchemes
        }), false)} />
    );
  }

  if(Types.string.validate(val) || Types.number.validate(val)) {
    return (<span>{val}</span>);
  }

  console.warn('[KlueTypeComponentFactory]: Unknown result type.', val);

  return (<div />);
};

KlueTypeComponentFactory.propTypes = {
  val: PropTypes.any,
  cardId: PropTypes.number.isRequired,
  cardCreatedAt: PropTypes.string,
  isDraft: PropTypes.bool,
  analyticsEventContext: PropTypes.object
};

KlueTypeComponentFactory.defaultProps = {
  val: null,
  cardCreatedAt: '',
  isDraft: false,
  analyticsEventContext: {}
};

export default KlueTypeComponentFactory;

import axios from 'axios';
import {createStore} from 'redux';

import {actionCacheSave} from './actions';
import appReducer, {getCacheValue} from './reducers/app';

const store = createStore(appReducer);

function getProviderData(providerId, providerUrl, params) {
  const key = `${providerId}:${JSON.stringify(params)}`;
  let request = getCacheValue(store.getState(), key);

  if(!request) {
    // Fetch Craft data from the server
    request = axios.get(providerUrl, {
      params,
      responseType: 'json'
    }).then(response => {
      if(response.status < 200 || response.status >= 400) {
        throw new Error(`Unexpected status code: ${response.status} ${response.statusText}`);
      }

      // Extract the actual content
      const responseJson = response.data;

      if(responseJson.error) {
        throw new Error(`API returned an error: ${responseJson.error}`);
      }

      return responseJson;
    });

    store.dispatch(actionCacheSave(key, request));
  }

  return request;
}

export function fetchCraftData(companyInfo) {
  const requestParam = {rivalId: companyInfo};

  return getProviderData('craft', '/api/providers/craft.json', requestParam).then(responseJson => {
    const rivalData = responseJson.data.company;

    if(!rivalData) {
      throw new Error(`Craft data unavailable for ${companyInfo}.`);
    }

    return rivalData;
  });
}

export function fetchCraftSavedData(companyInfo) {
  const requestParam = {rivalId: companyInfo};

  return getProviderData('craft_persisted', '/api/providers/craft_persisted.json', requestParam).then(responseJson => {
    const rivalData = responseJson.data.company;

    if(!rivalData) {
      throw new Error(`Craft data unavailable for ${companyInfo}.`);
    }

    return rivalData;
  });
}

export function fetchPredictLeadsData(companyInfo) {
  return getProviderData('predictleads', '/api/providers/predictleads.json', {rivalId: companyInfo}).then(responseJson => {
    const results = responseJson.data;

    if(!results) {
      throw new Error(`PredictLeads data unavailable for rival Id(${companyInfo}).`);
    }

    return results;
  });
}

export function fetchKlueData(companyInfo) {
  return getProviderData('klue', '/api/providers/klue.json', {rivalId: companyInfo}).then(responseJson => {
    const {result} = responseJson;

    if(!result) {
      throw new Error(`Klue data unavailable for rival Id(${companyInfo}).`);
    }

    return result;
  });
}

export function fetchSimilarWebData(companyInfo) {
  return getProviderData('similarweb', '/api/providers/similarweb.json', {rivalId: companyInfo}).then(responseJson => {
    const {data} = responseJson;

    if(!data) {
      throw new Error(`SimilarWeb data unavailable for ${companyInfo}.`);
    }

    return data;
  });
}

export function fetchContifyData(companyInfo, topics) {
  const requestParam = {rivalId: companyInfo};

  return getProviderData('contify', '/api/providers/contify.json', {...requestParam, topics}).then(responseJson => {
    const data = responseJson.results;

    if(!data) {
      throw new Error(`Contify data unavailable for ${companyInfo} and topics ${topics}.`);
    }

    return data;
  });
}

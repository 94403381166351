import BoardCollapsed from './_board_collapsed';
import Card from './card_templates/_card';
import CardAddButton from './_card_add_button';
import Dropdown from './_dropdown';
import Icon from './_icon';
import Observer from './_observer';

import {getKlueUrl} from '../modules/post_utils';
import {userCanCurate} from '../modules/roles_utils';
import {isValidId} from '../modules/utils';
import {highlightHTMLWithSearchWords, getNewCardViewOrder} from '../modules/card_utils';
import {wrapHtml} from '../modules/html_utils';
import {truncate, titleize} from '../modules/text_utils';
import {cardUpdate} from '../modules/api/cards';
import {AnalyticsEventProvider} from '../contexts/_analyticsEvent';
import EmptyLaneTargetCard from './_empty_lane_target_card';
import CardDragInsertionPoint from './_card_drag_insertion_point';
import DropTargetScroller from './_drop_target_scroller';
import {BeginningOfTime} from '../modules/constants/dropdown_menu';

import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import {debounce} from 'lodash';

class Board extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired,
    profileFilters: PropTypes.object
  };

  static propTypes = {
    cardDraggingContext: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    index: PropTypes.number,
    isLastBoard: PropTypes.bool,
    swimlanesRef: PropTypes.object,
    board: PropTypes.object,
    refreshCount: PropTypes.number,
    rivals: PropTypes.arrayOf(PropTypes.object),
    user: PropTypes.object,
    onBoardMove: PropTypes.func,
    onBoardDelete: PropTypes.func,
    onBoardsLoaded: PropTypes.func,
    onLaneCountRefresh: PropTypes.func,
    onInsertLaneAfter: PropTypes.func,
    boardEditor: PropTypes.object,
    showAddCardForm: PropTypes.bool,
    editMode: PropTypes.bool,
    battlecardCardsOnly: PropTypes.bool,
    battlecardCardIds: PropTypes.object,
    onSetEditMode: PropTypes.func,
    profileEditMode: PropTypes.bool,
    builderMode: PropTypes.bool,
    onToggleCardOnBattlecard: PropTypes.func,
    maxBattlecardCards: PropTypes.number,
    onShowCardMeta: PropTypes.func,
    onToggleLaneVisibility: PropTypes.func,
    collapsed: PropTypes.bool,
    onGetCommentSourceLink: PropTypes.func,
    viewportLoadMargin: PropTypes.number,
    cardTemplates: PropTypes.arrayOf(PropTypes.object),
    onScratchpadDismiss: PropTypes.func,
    onBoardRefresh: PropTypes.func,
    onCardRefresh: PropTypes.func,
    onToggleFreshCardExpanded: PropTypes.func,
    freshCardExpanded: PropTypes.object,
    moveCardToLane: PropTypes.func,
    focusedCardId: PropTypes.number,
    boards: PropTypes.arrayOf(PropTypes.object),
    lastCardUpdated: PropTypes.object,
    previewingId: PropTypes.number,
    onToggleSnapshots: PropTypes.func,
    cardSnapshotsId: PropTypes.number
  };

  static defaultProps = {
    cardDraggingContext: null,
    history: {},
    location: {},
    match: {},
    index: 0,
    isLastBoard: false,
    swimlanesRef: null,
    board: null,
    refreshCount: 0,
    rivals: [],
    user: null,
    onBoardMove: null,
    onBoardDelete: null,
    onBoardsLoaded: null,
    onLaneCountRefresh() {},
    onInsertLaneAfter() {},
    showAddCardForm: false,
    editMode: false,
    battlecardCardsOnly: false,
    battlecardCardIds: null,
    boardEditor: null,
    onSetEditMode: null,
    profileEditMode: false,
    builderMode: false,
    onToggleCardOnBattlecard: null,
    maxBattlecardCards: 0,
    onShowCardMeta: null,
    onToggleLaneVisibility: null,
    collapsed: false,
    onGetCommentSourceLink: null,
    viewportLoadMargin: 0,
    cardTemplates: [],
    onScratchpadDismiss() {},
    onBoardRefresh() {},
    onCardRefresh() {},
    onToggleFreshCardExpanded() {},
    freshCardExpanded: {},
    moveCardToLane() {},
    focusedCardId: null,
    boards: [],
    lastCardUpdated: {},
    previewingId: null,
    onToggleSnapshots() {},
    cardSnapshotsId: 0
  };

  state = {
    cardPositions: [],
    selectedTemplate: 'CardHTML',
    addCardIndex: 0,
    newCardTitle: '',
    cardPermission: {}
  };

  componentDidMount() {
    console.log('Board.componentDidMount: props: %o', this.props);

    const {cardDraggingContext, board, onBoardsLoaded} = this.props;

    this._isMounted = true;

    this.setState({
      cardPositions: this._getCardPositionsFromBoardCards(this.props)
    });

    if(onBoardsLoaded) {
      // last board in profile is loaded
      // NOTE: this ignores state of cards in board as they're lazy-loaded in once in view
      onBoardsLoaded();
    }

    this._recoveredCard = {text: '', title: '', template: ''};

    if(cardDraggingContext && board) {
      const {id: boardId} = board;

      cardDraggingContext.addObserver(this, {boardId}, `${board.id}`);
    }
  }

  componentDidUpdate() {
    this.refreshTooltips();
  }

  componentWillUnmount() {
    this._isMounted = false;

    const {cardDraggingContext, board} = this.props;

    if(cardDraggingContext && board) {
      cardDraggingContext.removeObserver(this, `${board.id}`);
    }
  }

  refreshTooltips = debounce(() => ReactTooltip.rebuild(), 1000);

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {cardPositions, newCardTitle} = this.state;
    const {board, showAddCardForm, cardDraggingContext, refreshCount} = this.props;
    const {board: nextBoard, showAddCardForm: nextShowAddCardForm, refreshCount: nextRefreshCount} = nextProps;
    const refreshCardPositions = (nextBoard?.id !== board?.id) || refreshCount !== nextRefreshCount;

    if(refreshCardPositions) {
      // refresh cardPositions
      this.setState({
        cardPositions: this._getCardPositionsFromBoardCards(nextProps)
      }, () => {
        console.log('Board.componentWillReceiveProps: refreshed cardPositions: %o', cardPositions);
      });
    }

    if(!nextShowAddCardForm && (showAddCardForm !== nextShowAddCardForm) && newCardTitle.length) {
      // reset card title field if add card form has been closed
      this.setNewCardTitle('');
    }
  }

  /* The following functions are required by the cardDragging context:
  'removeCardFromBoard',
  'addCardToBoard',
  'moveCardOnBoard',
  'getCard',
  'commitReorderedCards',
  'moveCardToEmptyLane',
  'getCurrentCardCount',
  'showDropInsertAtIndex'

  So they are accessed by that context and not within this file... hence the
  no-unused-react-component-methods
  */

  // eslint-disable-next-line no-unused-react-component-methods/no-unused-react-component-methods
  moveCardToEmptyLane = ({card, targetBoardId}) => {
    const {moveCardToLane} = this.props;

    moveCardToLane(card, targetBoardId);
  };

  // eslint-disable-next-line no-unused-react-component-methods/no-unused-react-component-methods
  removeCardFromBoard = ({cardId}) => {
    return new Promise((resolve, reject) => {
      const matched = this.handleFindCard(cardId);

      if(matched.card) {
        const {cardPositions} = this.state;

        this.setState({
          cardPositions: ReactUpdate(cardPositions, {
            $splice: [
              [matched.index, 1]
            ]
          })
        }, () => resolve());
      }
      else {
        reject();
      }
    });
  };

  // eslint-disable-next-line no-unused-react-component-methods/no-unused-react-component-methods
  addCardToBoard = ({card, targetCardId, targetCardIndex}) => {
    return new Promise((resolve, reject) => {
      const matched = targetCardId ? this.handleFindCard(targetCardId) : null;

      if(matched?.card || targetCardIndex || targetCardIndex === 0) {
        const {board: {id: boardId}} = this.props;
        const {cardPositions} = this.state;

        let index;

        if(matched?.index >= 0) {
          index = matched?.index;
        }
        else if(targetCardIndex === Number.MAX_SAFE_INTEGER) {
          index = cardPositions.length >= 0 ? cardPositions.length : -1;
        }
        else if(targetCardIndex >= 0) {
          index = targetCardIndex;
        }

        if(index >= 0) {
          this.setState({
            cardPositions: ReactUpdate(cardPositions, {
              $splice: [
                [index, 0, {...card, boardId, viewOrder: index}]
              ]
            })
          }, () => resolve());
        }
        else {
          reject();
        }
      }
      else {
        reject();
      }
    });
  };

  // eslint-disable-next-line no-unused-react-component-methods/no-unused-react-component-methods
  moveCardOnBoard = ({cardId, targetCardId, targetCardIndex}) => {
    return new Promise((resolve, reject) => {
      const targetMatch = targetCardId ? this.handleFindCard(targetCardId) : null;
      const targetMatchIndex = targetMatch?.index;
      let index = targetCardIndex >= 0 ? targetCardIndex : targetMatchIndex >= 0 ? targetMatchIndex : -1;
      const matched = this.handleFindCard(cardId);

      if(matched.card && index >= 0) {
        if(matched.index === index) {
          return resolve();
        }

        const {cardPositions} = this.state;

        if(index === Number.MAX_SAFE_INTEGER) {
          index = cardPositions.length >= 0 ? cardPositions.length : -1;
        }

        if(matched.index < index) {
          index -= 1;
        }

        this.setState({
          cardPositions: ReactUpdate(cardPositions, {
            $splice: [
              [matched.index, 1],
              [index, 0, matched.card]
            ]
          })
        }, () => resolve());
      }
      else {
        reject();
      }
    });
  };

  // eslint-disable-next-line no-unused-react-component-methods/no-unused-react-component-methods
  getCard = ({cardId}) => {
    return this.handleFindCard(cardId);
  };

  reorderAndSave = ({cardPositions, adding, boardId}) => {
    return new Promise((resolve, reject) => {
      const deferreds = [];

      try {
        for(let i = 0; i < cardPositions.length; i++) {
          const card = cardPositions[i];

          if(card) {
            card.viewOrder = i;
            // Anti-pattern https://github.com/kriasoft/react-starter-kit/issues/909#issuecomment-285336262
            // * Either do a bulk update of cards without a need to call children's methods separately (PUT to /api/cards.json)
            // * Or build an API method in back-end that specifically updates viewOrder of given cardIds (not REST,
            //   pass pairs (cardId, viewOrderId) for each card in board).
            deferreds.push(this.updateCardViewOrder(card.id, card.viewOrder, boardId));
          }
          else if(adding) {
            throw new Error({message: 'added card missing'});
          }
        }

        if(deferreds.length) {
          Promise.all(deferreds)
            .then(() => resolve())
            .catch(() => {
              reject({message: 'an error occurred while updating cards'});
            });
        }
        else {
          resolve();
        }
      }
      catch(error) {
        const {message = 'unknown error'} = error;

        reject(message);
      }
    });
  };

  // eslint-disable-next-line no-unused-react-component-methods/no-unused-react-component-methods
  commitReorderedCards = ({addToBoardId}) => {
    const {cardPositions} = this.state;
    const {board: {id: boardId} = {}} = this.props;
    const adding = addToBoardId === boardId;

    return this.reorderAndSave({cardPositions, adding, boardId});
  };

  getCurrentCardCount = () => {
    const {cardPositions} = this.state;

    return cardPositions.length;
  };

  // eslint-disable-next-line no-unused-react-component-methods/no-unused-react-component-methods
  showDropInsertAtIndex = dropInsertIndex => {
    this.setState({dropInsertIndex});
  };

  _getProfile = () => {
    const {profile = {}} = this.context.utils.rival || {};

    return profile;
  };

  _isFilteringProfile = () => {
    return this.props.profileEditMode ? false : Boolean(this.context.profileFilters.q);
  };

  _getCardPositionsFromBoardCards = ({board}) => board.cards.map(c => Object.assign(c, {boardId: board.id}));

  _preventDefault = e => e && e.preventDefault();

  handleCardStateUpdate = (card, callback = null) => {
    const {cardPermission} = this.state;
    const {id, visibilityGroups = [], isDraft = true, allAccess = false} = card;

    this.setState({
      cardPermission: Object.assign({}, cardPermission, {[id]: {isDraft, allAccess, visibilityGroups}})
    }, () => {
      return typeof callback === 'function' && callback(card);
    });
  };

  handleCardLoaded = card => this.handleCardStateUpdate(card);

  handleCardRefresh = ({card}) => {
    const {onCardRefresh} = this.props;

    this.handleCardStateUpdate(card, function(c) {
      onCardRefresh({card: c});
    });
  };

  handleMoveBoard = (dir, event) => {
    const {onBoardMove, board, index} = this.props;

    this._preventDefault(event);
    onBoardMove(board, index, dir);
  };

  handleDeleteBoard = event => {
    this._preventDefault(event);

    const {onBoardDelete, board} = this.props;
    const confirmMessage = `<strong>Are you sure you want to delete the "${board.name}" lane?</strong><br /><br />
      This will also delete any associated cards.`;

    this.context.utils.dialog.confirmRestrictive({
      message: confirmMessage,
      buttonOk: 'Yes - Delete Lane',
      okCallback() {
        onBoardDelete(board);
      }
    });
  };

  handleDeleteCard = cardId => {
    const {cardPositions} = this.state;
    const cardIndex = cardPositions.slice().findIndex(({id}) => id === cardId);

    if(cardIndex < 0) {
      return;
    }

    this.setState({
      cardPositions: ReactUpdate(cardPositions, {$splice: [[cardIndex, 1]]})
    });
  };

  toggleLaneVisibility = event => {
    this._preventDefault(event);

    const toggleLaneAction = () => {
      ReactTooltip.rebuild();

      this.props.onToggleLaneVisibility(this.props.board, event ? event.altKey : false);
    };

    toggleLaneAction();
  };

  toggleEditMode = () => this.props.onSetEditMode(!this.props.editMode, this.props.board.id);

  handleCommentDrop = (comment, index, viewOrder) => {
    console.log('Board.handleCommentDrop: comment: %o, index: %o, viewOrder: %o', comment, index, viewOrder);

    this.showAddCard({index, viewOrder, comment});
  };

  getSourceFromComment = comment => {
    const source = {};

    if(comment.highlight) {
      // source: highlight
      const {highlight} = comment;

      Object.assign(source, {
        id: comment.id,
        highlightId: highlight.id,
        pageTitle: comment.pageTitle,
        itemTitle: '',
        url: highlight.url,
        targetUrl: getKlueUrl(highlight.url, highlight.postId),
        userId: highlight.userId,
        userName: comment.userName,
        userImage: comment.userImage,
        prettyName: comment.prettyName,
        createdAt: highlight.createdAt
      });
    }
    else {
      // source: comment
      const containers = comment.containers || [];
      let postId = null;
      let linkOptions = {
        label: '',
        link: ''
      };

      if(containers.length) {
        const postContainer = containers.find(c => c.containerType === 'Post');

        if(postContainer) {
          postId = postContainer.containerId;
        }
        else {
          linkOptions = this.props.onGetCommentSourceLink(comment);
        }
      }

      Object.assign(source, {
        id: comment.id,
        itemTitle: comment.pageTitle || linkOptions.label,
        pageTitle: comment.pageTitle || truncate(comment.body, {limit: 100, useWordBoundary: true}),
        url: comment.pageUrl || linkOptions && linkOptions.link,
        targetUrl: comment.pageUrl ? getKlueUrl(comment.pageUrl, postId) : linkOptions && linkOptions.link,
        userId: comment.userId,
        userName: comment.userName,
        userImage: comment.userImage,
        prettyName: comment.prettyName,
        createdAt: comment.createdAt
      });
    }

    return source;
  };

  showAddCard = ({index, viewOrder, comment}) => {
    console.log('Board.showAddCard: index: %o, viewOrder: %o, comment: %o', index, viewOrder, comment);

    const {utils: {isNewCardEditorEnabled}} = this.context;
    const {newCardTitle, selectedTemplate} = this.state;
    const {board: {id: boardId}, builderMode, history, match: {params = {}}, previewingId} = this.props;
    const sources = comment ? this.getSourceFromComment(comment) : null;
    const newCardState = {
      addCardIndex: index,
      useViewOrder: viewOrder,
      sources,
      newCardTitle: this._recoveredCard.title || newCardTitle,
      selectedTemplate: this._recoveredCard.template || selectedTemplate
    };

    this.setState(newCardState, () => {
      // pass additional data to editor modal
      const state = {...newCardState, comment, boardId, builderMode, previewingId};

      if(builderMode && params.battlecardId) {
        state.battlecardId = parseInt(params.battlecardId, 10);
      }

      const queryString = isNewCardEditorEnabled() ? `?laneId=${boardId}&viewOrder=${viewOrder}` : '';
      const pathname = `/profile/${params.profileId}/edit/card/new${queryString}`;

      history.push({
        pathname,
        state
      });
    });
  };

  handleCardTitlesRefresh = updatedCard => {
    const cardName = updatedCard.data.name || this.props.board.name;

    // update card titles in current rival's battlecards
    this.refreshCardTitles({id: updatedCard.id, name: cardName});
  };

  handleScratchpadDismiss = (commentToDismiss = {id: 0}) => {
    if(!_.isEmpty(commentToDismiss) && commentToDismiss.id) {
      // publish event specifically to scratchpad board
      this.props.onScratchpadDismiss(commentToDismiss);
    }
  };

  refreshCardTitles = (card = {id: 0, name: ''}) => {
    const profile = this._getProfile();
    const battlecards = profile && profile.battlecards;
    let doUpdate = false;

    if(_.isEmpty(profile) || _.isEmpty(battlecards) || _.isEmpty(card) || !isValidId(card.id)) {
      return;
    }

    profile.battlecards = battlecards.map(board => {
      const cardIndex = board.cardTitles.findIndex(ct => ct.id === card.id);

      if(cardIndex >= 0) {
        board.cardTitles[cardIndex].name = card.name;

        if(!doUpdate) {
          doUpdate = true;
        }
      }

      return board;
    });

    if(doUpdate) {
      this.context.utils.refreshRivalProfile(profile).then(updatedProfile => {
        console.log('Board.refreshCardTitles: updated card titles for profile: %o', updatedProfile);
      });
    }
  };

  handleMergeCard = (cardId, mergeItem, updating = false) => {
    const {board, builderMode, match: {params = {}}} = this.props;
    const cardPositions = this.state.cardPositions.slice();
    const card = cardPositions.slice().filter(c => c.id === cardId)[0];
    const cardIndex = cardPositions.indexOf(card);
    const comment = mergeItem ? mergeItem.comment : null;

    if(!mergeItem || mergeItem.comment) {
      // append merged scratchpad item's contents
      if(comment) {
        // note: merge datestamp only used for comparisons
        mergeItem.mergedAt = moment().format();

        _.extend(card, {
          mergeContent: mergeItem,
          getSourceObject: this.getSourceFromComment
        });
      }
      else {
        _.extend(card, {
          mergeContent: null,
          getSourceObject: null
        });
      }

      if(!updating) {
        const sources = comment ? this.getSourceFromComment(comment) : null;
        // pass additional data to editor modal
        const state = {sources, comment, builderMode, boardId: board.id};

        if(builderMode && params.battlecardId) {
          state.battlecardId = parseInt(params.battlecardId, 10);
        }

        this.props.history.push({
          pathname: `/profile/${params.profileId}/edit/card/${card.id}/edit`,
          state
        });
      }

      const updatedCardPositions = {};

      updatedCardPositions[cardIndex] = {$set: card};

      this.setState({
        cardPositions: ReactUpdate(this.state.cardPositions, updatedCardPositions)
      });
    }
  };

  handleMoveCard = (cardId, atIndex) => {
    const matched = this.handleFindCard(cardId);

    if(matched.card) {
      this.setState({
        cardPositions: ReactUpdate(this.state.cardPositions, {
          $splice: [
            [matched.index, 1],
            [atIndex, 0, matched.card]
          ]
        })
      });
    }
  };

  handleFindCard = id => {
    const {board: {id: boardId}} = this.props;
    const {cardPositions} = this.state;
    const card = cardPositions.filter(c => c.id === id)[0];

    if(card && (card.boardId === boardId)) {
      const index = cardPositions.indexOf(card);

      return {
        card: {...card},
        index
      };
    }

    return {
      card: null,
      index: -1
    };
  };

  updateCardViewOrder = (cardId, viewOrder, boardId) => {
    return cardUpdate({
      id: cardId,
      data: {
        keepUpdatedAt: true,
        viewOrder,
        boardId
      }
    });
  };

  handleSetEditMode = (cardId, showCardPermissions = false) => {
    const {board: {id: boardId}, builderMode, match: {params}, location = {}} = this.props;
    const state = {boardId, builderMode, showCardPermissions};

    if(builderMode && params.battlecardId) {
      state.battlecardId = parseInt(params.battlecardId, 10);
    }

    this.props.history.push({
      pathname: `/profile/${params.profileId}/edit/card/${cardId}/edit`,
      search: location.search,
      state
    });
  };

  renderBoard = board => {
    const {
      index: boardIndex, isLastBoard, boardEditor, viewportLoadMargin, swimlanesRef, focusedCardId,
      user, rivals, editMode, profileEditMode, builderMode, showAddCardForm, maxBattlecardCards,
      onBoardRefresh, onToggleCardOnBattlecard, onShowCardMeta, moveCardToLane, boards, lastCardUpdated, previewingId,
      onToggleSnapshots, cardSnapshotsId, cardDraggingContext, onLaneCountRefresh, onInsertLaneAfter, freshCardExpanded,
      onToggleFreshCardExpanded, battlecardCardsOnly, battlecardCardIds
    } = this.props;
    const previewing = (previewingId !== null);
    const {addCardIndex, cardPositions, cardPermission, dropInsertIndex} = this.state;
    const {profileFilters, utils: {isInsertBetweenLanesEnabled}} = this.context;
    const canInsertBetweenLanes = isInsertBetweenLanesEnabled();
    const isCurator = userCanCurate({user});
    const isRecoveringUnsavedCard = this._recoveredCard && (this._recoveredCard.text || this._recoveredCard.title);
    const boardName = board ? board.name : '';
    const boardClass = `ui-card board board--${board.id || 'new'}`;
    let cards = cardPositions ? cardPositions.slice() : [];
    let uiBoardBody;
    let boardActionsRegion;
    let boardTitleRegion;
    let footerAddCardBlock;
    let isFreshMode = false;

    // TODO: refactor some of this display logic into helpers or sub-components
    if(cards.length) {
      const cardNodes = cards.map((item, index) => {
        const cardEditingProps = {};
        const permissions = cardPermission[item.id];
        const {isDraft, allAccess} = permissions || {};
        const permissionsEnabled = isCurator && permissions;
        const viewOrder = getNewCardViewOrder({cards, index});

        let addCardBlock;
        let cardFilters;
        let adding = false;

        if(profileEditMode) {
          let insertIndex = addCardIndex;

          if(isRecoveringUnsavedCard && addCardIndex >= cards.length) {
            insertIndex = cards.length ? cards.length - 1 : 0;
          }

          if((showAddCardForm || isRecoveringUnsavedCard) && (index === insertIndex)) {
            adding = true;
          }
          else if(!previewing && !cardDraggingContext?.isCompressedCardDraggingOn) {
            const addCardKey = `cardAdd_b${board.id}_${index}`;

            addCardBlock = (
              <CardAddButton
                key={addCardKey}
                user={user}
                index={index}
                useViewOrder={viewOrder}
                onAddClick={() => this.showAddCard({index, viewOrder})}
                onDrop={this.handleCommentDrop} />
            );
          }

          Object.assign(cardEditingProps, {
            index,
            onUpdateCardRefreshTitles: this.handleCardTitlesRefresh,
            onUpdateCardDismissScratchpad: this.handleScratchpadDismiss,
            onMoveCard: this.handleMoveCard,
            onFindCard: this.handleFindCard,
            onMergeCard: this.handleMergeCard,
            dupeCardPosition: viewOrder
          });

          if(item.mergeContent) {
            Object.assign(cardEditingProps, {
              mergeContent: item.mergeContent,
              getSourceObject: this.getSourceFromComment
            });
          }
        }

        if(profileFilters) {
          cardFilters = {
            q: profileFilters.q || '',                         // search title / body if has query
            updatedSince: profileFilters.updatedSince || null  // valid moment.js string (wont filter on null/false/invalid)
          };
        }
        else {
          cardFilters = false;  // show card
        }

        isFreshMode = profileFilters && profileFilters.updatedSince === BeginningOfTime;

        // TODO: remove unnecessary props used to handle the legacy editor
        const _renderCard = isVisible => (
          <Card
            id={item.id}
            reviewer={item.reviewer}
            cardDraggingContext={cardDraggingContext}
            boardId={board.id}
            isVisible={isVisible}
            isCollapsed={cardDraggingContext?.isCompressedCardDraggingOn}
            onRef={ref => (this[`card-${item.id}`] = ref)}
            dupeCardPosition={viewOrder}
            user={user}
            rivals={rivals}
            boards={boards}
            profileEditMode={profileEditMode}
            onSetEditMode={this.handleSetEditMode}
            onToggleFreshCardExpanded={onToggleFreshCardExpanded}
            freshCardExpanded={freshCardExpanded}
            builderMode={builderMode}
            onCardLoaded={this.handleCardLoaded}
            onDeleteCard={this.handleDeleteCard}
            onToggleCardOnBattlecard={onToggleCardOnBattlecard}
            maxBattlecardCards={maxBattlecardCards}
            onShowCardMeta={onShowCardMeta}
            moveCardToLane={moveCardToLane}
            onToggleSnapshots={onToggleSnapshots}
            cardSnapshotsId={cardSnapshotsId}
            filtersToShowCard={cardFilters}
            battlecardCardsOnly={battlecardCardsOnly}
            battlecardCardIds={battlecardCardIds}
            onBoardRefresh={onBoardRefresh}
            onCardRefresh={this.handleCardRefresh}
            onLaneCountRefresh={onLaneCountRefresh}
            showCardPermissions={isCurator}
            lastCardUpdated={lastCardUpdated}
            previewingId={previewingId}
            {...cardEditingProps} />
        );

        const cardClass = classNames('card-item', {
          'visible-curators_only-tag': permissionsEnabled && isDraft && !previewing,
          'visible-everyone-tag': permissionsEnabled && !isDraft && allAccess && !previewing,
          'visible-curatorsandgroups-tag': permissionsEnabled && !isDraft && !allAccess && !previewing,
          adding,
          reordering: cardDraggingContext?.isCompressedCardDraggingOn
        });

        const willRenderCard = !isFreshMode || !battlecardCardsOnly || battlecardCardIds.has(item.id);

        if(!willRenderCard) {
          return null;
        }

        if(focusedCardId === item.id) {
          return (
            <li key={item.id} data-card-id={item.id} className={cardClass}>
              {_renderCard(true)}
              {dropInsertIndex === Number.MAX_SAFE_INTEGER && index === cards.length - 1 ? <CardDragInsertionPoint /> : null}
              {!isFreshMode && addCardBlock}
            </li>
          );
        }

        return (
          <li key={item.id} data-card-id={item.id} className={cardClass}>
            <Observer root={swimlanesRef} rootMargin={`0px ${viewportLoadMargin}px 0px 0px`} className="card-item_inner">
              {isVisible => {
                return _renderCard(isVisible);
              }}
            </Observer>
            {dropInsertIndex === Number.MAX_SAFE_INTEGER && index === cards.length - 1 ? <CardDragInsertionPoint /> : null}
            {!isFreshMode && addCardBlock}
          </li>
        );
      });

      cards = (
        <ul className="card-list">
          {cardNodes}
          {cardDraggingContext?.isCompressedCardDraggingOn ? <EmptyLaneTargetCard boardId={board.id} onLaneCountRefresh={onLaneCountRefresh} /> : null}
        </ul>
      );
    }
    else if(cardDraggingContext?.isCompressedCardDraggingOn) {
      cards = (
        <>
          {dropInsertIndex ? <CardDragInsertionPoint /> : null}
          <EmptyLaneTargetCard boardId={board.id} onLaneCountRefresh={onLaneCountRefresh} />
        </>
      );
    }
    else {
      cards = [];
    }

    if(profileEditMode && !previewing && !cardDraggingContext?.isCompressedCardDraggingOn && !isFreshMode) {
      if(Array.isArray(cards) && !cards.length) {
        const footerAddCardLink = (
          <div className="card-item">
            <CardAddButton
              user={user}
              index={0}
              useViewOrder={0}
              onAddClick={() => this.showAddCard({index: 0, viewOrder: 0})}
              onDrop={this.handleCommentDrop} />
          </div>
        );

        const insertIndex = isRecoveringUnsavedCard ? 0 : addCardIndex;

        footerAddCardBlock = ((showAddCardForm || isRecoveringUnsavedCard) && (insertIndex === 0)) ? '' : footerAddCardLink;
      }
    }

    if(board.id) {
      let userUIDropdownEditOptions = [];

      uiBoardBody = (
        <div id={`board_body_${board.id}`}className="board-body">
          {cards}
          {footerAddCardBlock}
        </div>
      );

      const handleInsertLaneAfter = () => {
        onInsertLaneAfter(board, newBoard => {
          const {onSetEditMode} = this.props;

          onSetEditMode(true, newBoard.id);
        });
      };

      if(!editMode && !previewing && isCurator && profileEditMode) {
        userUIDropdownEditOptions = [
          {value: 0, label: 'Rename Lane', icon: 'fa-i-cursor', onOptionClick: this.toggleEditMode},
          {
            value: 1,
            label: 'Collapse Lane',
            icon: (<Icon icon="collapse" width="24" height="24" />), onOptionClick: this.toggleLaneVisibility
          }
        ];

        if(canInsertBetweenLanes) {
          userUIDropdownEditOptions.push({value: 4, label: 'Insert Lane After', icon: 'fa-plus-circle', onOptionClick: handleInsertLaneAfter});
        }

        if(previewingId === null) {
          userUIDropdownEditOptions.push({value: 2, label: 'Delete Lane & Cards', icon: 'fa-trash', onOptionClick: this.handleDeleteBoard});
        }

        boardActionsRegion = (
          <div>
            <div
              className="btn btn-board-header btn-dropdown-size-38"
              data-tip={boardIndex < 1 ? null : 'Move Lane Left'}
              data-offset="{'top': 0}"
              disabled={boardIndex < 1}
              onClick={e => boardIndex >= 1 && this.handleMoveBoard(-1, e)}>
              <i className="fa fa-chevron-left" />
            </div>
            <div
              className="btn btn-board-header btn-dropdown-size-38"
              data-tip={isLastBoard ? null : 'Move Lane Right'}
              data-offset="{'top': 0}"
              disabled={isLastBoard}
              onClick={e => !isLastBoard && this.handleMoveBoard(1, e)}>
              <i className="fa fa-chevron-right" />
            </div>
            <Dropdown
              options={userUIDropdownEditOptions}
              condensed={true}
              containerClass="ui-dropdown-flush"
              className="btn btn-board-header btn-dropdown-size-38" />
          </div>
        );
      }
      else if(!editMode) {
        // disable lane collapse toggle if currently filtering profile
        const disableCollapseClass = this._isFilteringProfile() ? 'btn-board-header--disabled' : '';

        boardActionsRegion = (
          <div>
            <div
              className={`btn btn-board-header btn-board-header--collapse ${disableCollapseClass} btn-dropdown-size-38`}
              data-tip={`Collapse ${board.name}`}
              data-offset="{'top': 0}"
              onClick={this.toggleLaneVisibility}>
              <Icon icon="collapse" width="24" height="24" />
            </div>
          </div>
        );
      }
    }

    if(isCurator && editMode) {
      boardTitleRegion = React.cloneElement(boardEditor, {
        board,
        onToggleEditMode: this.toggleEditMode
      });
    }
    else {
      const boardNameClass = classNames('board-name', {
        'board-name--editable': profileEditMode
      });
      const boardTitleClass = classNames('board-header_title', {
        'board-header_title--editmode': profileEditMode
      });

      const boardTitle = titleize(boardName);
      let boardTitleHtml = boardTitle;

      if(profileFilters?.q) {
        if(boardTitle) {
          // make a copy of the original name
          boardTitleHtml = highlightHTMLWithSearchWords(
            profileFilters.q,
            boardTitle
          );
        }
      }

      boardTitleRegion = (
        <div className={boardTitleClass} title={boardTitle}>
          <span className={boardNameClass}>
            <i className="fa fa-inbox board-header_title_icon" />
            <span dangerouslySetInnerHTML={wrapHtml(boardTitleHtml)} />
          </span>
        </div>
      );
    }

    const handleScrollDown = () => {
      document.getElementById(`board_body_${board.id}`)?.scrollBy(0, cardDraggingContext?.isCompressedCardDraggingOn ? -12 : -25);
    };

    return (
      <div id={`b${board.id}`} className={boardClass}>
        <div className="board-header">
          {boardTitleRegion}
          <div className="board-header_actions">
            {boardActionsRegion}
          </div>
          <DropTargetScroller position="bottom" narrow={true} onDropTargetScrollerHover={handleScrollDown} />
        </div>
        {uiBoardBody}
      </div>
    );
  };

  render() {
    const {board, collapsed} = this.props;

    if(collapsed) {
      const visibleCardsCount = this.getCurrentCardCount();

      return (
        <BoardCollapsed
          board={board}
          cardsCount={visibleCardsCount}
          onToggleClick={this.toggleLaneVisibility} />
      );
    }

    return (
      <AnalyticsEventProvider tagClickEvent={{category: 'Profile'}} viewContext="board">
        {this.renderBoard(board)}
      </AnalyticsEventProvider>
    );
  }

}

export default Board;


import Modal from '../_modal';
import {withRouter} from 'react-router-dom';
import Wizard from './_wizard';
import Step from './_wizard_step';
import AddCards from './_wizard_add_cards';
import InitialStep from './_wizard_initial_step';
import {dynamicCardGroupsForSelector, newBoardDefaultGroupItems, templateGroupsForSelector} from '../../modules/new_board_wizard_utils';
import {rivalsList} from '../../modules/api/rivals';

class NewBoardWizard extends React.PureComponent {

  static contextTypes = {
    api: PropTypes.object.isRequired
  };

  static propTypes = {
    activeGroup: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onCreateBoard: PropTypes.func.isRequired
  };

  static defaultProps = {
    activeGroup: null
  };

  state = {
    dynamicCardGroups: [],
    templateGroups: [],
    rivals: []

  };

  componentDidMount() {
    this.mounted = true;

    const rivalOptions = {
      order: 'alpha'
    };

    rivalsList({rivalOptions, code: 'NewBoardWizard.componentDidMount'}).then(rivals => {
      this.setState({rivals, dynamicCardGroups: dynamicCardGroupsForSelector()});
    });

    templateGroupsForSelector()
      .then(templateGroups => {
        if(!this.mounted) {
          return;
        }

        this.setState({templateGroups});
      }).catch(err => console.log(err));
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  mounted = false;

  initialValues = () => {
    const {activeGroup} = this.props;

    const initialValues = {};

    if(activeGroup) {
      initialValues.groups = [activeGroup];
    }

    return initialValues;
  };

  render() {
    const {onClose, onCreateBoard} = this.props;
    const {dynamicCardGroups, templateGroups, rivals} = this.state;

    const initialValues = this.initialValues();
    const nextValues = {automatedCards: newBoardDefaultGroupItems(dynamicCardGroupsForSelector())};

    return (
      <Modal header={false} padded={false} basic={true} onClose={onClose} closeOnOutsideClick={false}>
        <Wizard
          onSubmit={onCreateBoard}
          onClose={onClose}
          initialValues={initialValues}
          nextValues={nextValues}
          rivals={rivals}>
          <Step
            title="Let's build your new Klue Board"
            showError={true}>
            <InitialStep
              rivals={rivals} />
          </Step>
          <Step
            title="Add Cards to your new Board"
            showError={true}>
            <AddCards
              templateGroups={templateGroups}
              automatedCardGroups={dynamicCardGroups} />
          </Step>

        </Wizard>

      </Modal>
    );
  }

}

export default withRouter(NewBoardWizard);

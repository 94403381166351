import {useState, useEffect} from 'react';

import Spinner from './_spinner';

const OverlayLoader = ({message, scrollingMessages, showSpinner, visible, children, isReadyAndSuccess}) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => setCount(prev => prev + 1), 7000); // 7 second wait

    return () => clearTimeout(timeout);
  }, [count]);

  if(!visible) {
    return null;
  }

  const i = count % scrollingMessages.length;
  const srollMessage = (scrollingMessages && scrollingMessages.length) ? scrollingMessages[i] : null;
  const displayedMessage = message || srollMessage;

  return (
    <div className="overlay-loader">
      <div className="overlay-loader_box">
        <div className={isReadyAndSuccess ? 'overlay-loader_box-content overlay-loader_success' : 'overlay-loader_box-content'}>
          {showSpinner && <div className="overlay-loader_box-content_spinner"><Spinner /></div>}
          {displayedMessage && (
            <div className="overlay-loader_box-content_message" dangerouslySetInnerHTML={{__html: displayedMessage}} />
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

OverlayLoader.propTypes = {
  message: PropTypes.string,
  scrollingMessages: PropTypes.arrayOf(PropTypes.string),
  showSpinner: PropTypes.bool,
  visible: PropTypes.bool,
  children: PropTypes.node,
  isReadyAndSuccess: PropTypes.bool
};

OverlayLoader.defaultProps = {
  message: 'Loading...',
  scrollingMessages: [],
  showSpinner: true,
  visible: false,
  children: null,
  isReadyAndSuccess: false
};

export default OverlayLoader;

import Types from '../../types';
import * as KlueTypes from '../../klue_types';
import {fetchCraftSavedData} from './data_providers';

export async function officeLocations(company, craftData = fetchCraftSavedData) {
  if(!KlueTypes.Company.validate(company)) {
    console.warn('Unexpected parameter type.', company);
    throw new Error('Parameter was unexpected type.');
  }

  const data = await craftData(company.info);

  if(!data || !data.locations) {
    throw new Error('officeLocations: no location data in craft response.');
  }

  const places = data.locations.map(loc => {
    return KlueTypes.GeoPlace.create({
      label: `${loc.city}, ${loc.country}${loc.hq ? ' (HQ)' : ''}`,
      latitude: loc.latitude,
      longitude: loc.longitude
    });
  });

  return Types.arrayOf(KlueTypes.GeoPlace).create(places);
}

import {rivalsList} from '../modules/api/rivals';
import {attachments} from '../modules/editor_utils';
import {binarySearch} from '../modules/rival_utils';
import {rivalGroupsGet} from '../modules/api/rival_groups';

import {BOARD_NAME_EXISTS_MESSAGE} from '../modules/constants/new_board_wizard';

export const dynamicCardGroupsForSelector = (keysToExclude = ['Salesforce', 'Others']) => Object.keys(attachments).reduce((acc, key) => {
  if(keysToExclude.includes(key)) {
    return acc;
  }

  const items = attachments[key];
  const adjustedItems = Object.keys(items).reduce((acc2, key2) => {
    const {label, newBoardDefault} = items[key2];

    acc2.push({name: label, id: key2, newBoardDefault});

    return acc2;
  }, []);

  acc.push({title: key, items: adjustedItems});

  return acc;
}, []);

export const allGroupItems = groups => groups.reduce((acc, {items = []}) => {
  acc.push(...items);

  return acc;
}, []);

export const newBoardDefaultGroupItems = groups => allGroupItems(groups).filter(({newBoardDefault}) => newBoardDefault);

export const templateGroupsForSelector = () => {
  return new Promise((resolve, reject) => {
    rivalsList({code: 'NewBoardWizardUtils.templateGroupsForSelector'})
      .then(rivals => {
        const {templates, boards} = rivals.reduce((acc, rival) => {
          if(rival.isTemplate) {
            acc.templates.push(rival);
          }
          else {
            acc.boards.push(rival);
          }

          return acc;
        }, {templates: [], boards: []});

        const templateGroups = [];

        if(templates.length) {
          templateGroups.push({
            title: 'templates',
            items: templates
          });
        }

        if(boards.length) {
          templateGroups.push({
            title: 'boards',
            items: boards,
            collapsed: Boolean(templates.length)
          });
        }

        resolve(templateGroups);
      })
      .catch(err => reject(err));
  });
};

export const getTooltip = (value, type) => {
  if(!value) {
    return '';
  }

  if(type === 'radio') {
    return value.name;
  }

  return value.reduce((acc, item) => {
    const {name, label} = item;

    if(name || label) {
      return acc.concat(`${acc.length ? ',\n' : ''}${name || label}`);
    }

    return acc;
  }, '');
};

export const validateBoardName = (value, rivals, allowlistInLowercase = []) => {
  const lowercasedValue = (value || '').trim().toLowerCase();
  const found = binarySearch(rivals, lowercasedValue, 'name', (val, query) => {
    return val.toLowerCase().localeCompare(query);
  });

  if(!found || allowlistInLowercase.includes(lowercasedValue)) {
    return null;
  }

  return BOARD_NAME_EXISTS_MESSAGE;
};

const groupSort = (a, b) => {
  const aName = (a.name ?? '').toUpperCase();
  const bName = (b.name ?? '').toUpperCase();

  if(aName < bName) {
    return -1;
  }

  if(aName > bName) {
    return 1;
  }

  // names must be equal
  return 0;
};

export const getGroups = (rival, rivalGroups) => {
  const filtered = rivalGroups.filter(g => Boolean(g.id)).sort(groupSort); // strip out ungrouped group & sort
  const {id} = rival || {};
  let groupsThatRivalBelongsTo = [];

  if(id) {
    groupsThatRivalBelongsTo = rivalGroups.reduce((acc, group) => {
      const {rivals} = group;

      if(rivals.find(r => (r.id === id) && group.id)) {
        acc.push(group);
      }

      return acc;
    }, []);
  }

  return {rivalGroups: filtered, groups: [{title: 'groups', items: Object.values(filtered)}], groupsThatRivalBelongsTo};
};

export const getGroupedRivalGroups = rival => {
  return new Promise((resolve, reject) => {
    rivalGroupsGet({})
      .then(rivalGroups => {
        resolve(getGroups(rival, rivalGroups));
      }).catch(err => reject(err));
  });
};

export const getExistingGroup = ({existingGroups, groupName}) => {
  const groupNameLowerCase = groupName.toLowerCase();

  return existingGroups.find(
    g => g.name.toLowerCase() === groupNameLowerCase
  );
};

export const addNewRivalGroup = (groupName, existingGroups, rivalGroupCreate) => {
  return new Promise((resolve, reject) => {
    if(!groupName) {
      return reject({
        error: {
          title: '🚨 We couldn\'t add your group!',
          message: 'Please enter a name for your group.'
        }
      });
    }

    const existingGroup = getExistingGroup({existingGroups, groupName});

    if(existingGroup) {
      getGroupedRivalGroups()
        .then(({rivalGroups, groups}) => resolve({rivalGroups, rivalGroup: existingGroup, groups}))
        .catch(error => reject({error}));

      return;
    }

    const rivalGroupOptions = {
      name: groupName
    };

    rivalGroupCreate(rivalGroupOptions, (rivalGroup, errorText) => {
      if(errorText) {
        const message = 'Sorry, we encountered an error when creating your group<br /><br /><em>Error: ' + errorText + '</em>';

        return reject({
          error: {
            title: '🚨 Unable to create group!',
            message
          }
        });
      }

      getGroupedRivalGroups()
        .then(({rivalGroups, groups}) => {
          resolve({rivalGroups, rivalGroup, groups}); // updates the selected value
        }).catch(error => reject({error}));
    });
  });
};

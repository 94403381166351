import onClickOutside from 'react-onclickoutside';
import {attachments} from '../../modules/editor_utils';

class EditorToolbarAttachments extends React.PureComponent {

  static contextTypes = {
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    onOptionClick: PropTypes.func.isRequired,
    onClose: PropTypes.func
  };

  static defaultProps = {
    onOptionClick() {},
    onClose() {}
  };

  handleClickOutside = () => this.props.onClose();

  showAttachmentGroup = attachment => {
    if(attachment === 'Others') {
      return false;
    }

    return true;
  };

  render() {
    return (
      <div className="modal-editor-attachments">
        <div className="dropdown editor-dropdown">
          <div className="dropdown-menu editor-dropdown-menu">
            {Object.keys(attachments).filter(attachment => this.showAttachmentGroup(attachment)).map(key => (
              <div key={key} className="dropdown-item editor-dropdown-item">
                <span className="editor-dropdown-item-label">{key}</span>
                <div className="editor-dropdown-submenu">
                  {Object.keys(attachments[key]).map(itemKey => (
                    <button key={itemKey} onClick={() => this.props.onOptionClick(itemKey)} className="editor-dropdown-submenu-item">
                      {attachments[key][itemKey].label}
                    </button>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

}

export default onClickOutside(EditorToolbarAttachments);

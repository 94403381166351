import CardVitalsTocList from './_card_vitals_toc_list';

import {userCanCurate} from '../modules/roles_utils';
import {dig} from '../modules/utils';
import {handleScrollToLane} from '../modules/lane_utils';
import {uiDefaultTypes} from '../modules/constants/ui';

import moment from 'moment';
import {withRouter} from 'react-router-dom';
import 'intersection-observer';

class CardVitalsToc extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    history: PropTypes.object,
    user: PropTypes.object,
    profileId: PropTypes.number,
    editMode: PropTypes.bool,
    lanes: PropTypes.arrayOf(PropTypes.object),
    onBoardUpdate: PropTypes.func,
    onToggleLaneVisibility: PropTypes.func,
    onToggleCuratorTools: PropTypes.func
  };

  static defaultProps = {
    history: {},
    user: {},
    profileId: 0,
    editMode: false,
    lanes: [],
    onBoardUpdate() {},
    onToggleLaneVisibility() {},
    onToggleCuratorTools() {}
  };

  state = {
    battlecardsOpen: false,
    lanesOpen: false,
    lanesLoading: false
  };

  componentDidMount() {
    console.log('CardVitalsToc.componentDidMount: props: %o', this.props);

    const {user: {userData = {}}} = this.props;

    if(!_.isEmpty(userData)) {
      const defaults = _.extend({}, userData.defaults);
      const boardDefaults = defaults.boards || {};

      // user has personal defaults set already
      if(!_.isEmpty(boardDefaults)) {
        this.setState({
          battlecardsOpen: boardDefaults.tocBattlecardsOpen,
          lanesOpen: boardDefaults.tocLanesOpen
        });
      }
      else {
        // default to battlecards expanded for users who have no preference set
        this.setState({
          battlecardsOpen: true
        });
      }
    }
  }

  _preventDefault = e => e && e.preventDefault();

  _getIsCollapsed = laneId => {
    if(!laneId) {
      return false;
    }

    const {rival, getCollapsedLanes} = this.context.utils;
    const {profile = {}} = rival || {};
    const collapsedLanes = getCollapsedLanes(profile.id) || [];

    return collapsedLanes.includes(laneId);
  };

  isCuratorInEditMode = () => {
    const {user, editMode} = this.props;

    return editMode && userCanCurate({user});
  };

  saveUserTocPreferences = () => {
    const {user: {id}} = this.props;
    const {battlecardsOpen: tocBattlecardsOpen, lanesOpen: tocLanesOpen} = this.state;

    const featureFlag = [['defaults', 'boards'], {
      tocBattlecardsOpen,
      tocLanesOpen
    }];

    const userOptions = {
      id,
      featureFlag
    };

    this.context.api.userUpdate(userOptions);
  };

  handleToggleBattlecards = e => {
    this._preventDefault(e);

    this.setState(prevState => ({battlecardsOpen: !prevState.battlecardsOpen}), this.saveUserTocPreferences);
  };

  handleToggleLanes = e => {
    this._preventDefault(e);

    this.setState(prevState => ({lanesOpen: !prevState.lanesOpen}), this.saveUserTocPreferences);
  };

  handleAddBattlecardClick = e => {
    this._preventDefault(e);

    this.props.history.push(`/profile/${this.props.profileId}/battlecard/edit/new`);
  };

  handleAddLaneClick = e => {
    this._preventDefault(e);

    const {onToggleLaneVisibility} = this.props;

    handleScrollToLane({id: 0}, this._getIsCollapsed(0), onToggleLaneVisibility);
  };

  handleLoadBattlecard = bc => {
    const url = `/profile/${bc.profile.id}/battlecard${this.props.editMode ? '/edit' : ''}/${bc.id}`;

    this.props.history.push({
      pathname: url,
      state: {
        source: uiDefaultTypes.TOC
      }
    });
  };

  handleLanesReorder = items => {
    const allLanes = items || [];

    this.setState({
      lanesLoading: true
    }, () => {
      let count = 0;

      allLanes.forEach((lane, idx) => {
        if(!_.isEmpty(lane)) {
          const {onBoardUpdate} = this.props;
          const boardOptions = {
            id: lane.id,
            // plus 1 because of scratchpad
            viewOrder: parseFloat(idx + 1.0)
          };
          const callback = () => {
            count += 1;

            if(count === allLanes.length) {
              this.setState({lanesLoading: false});
            }
          };

          onBoardUpdate(boardOptions, callback);
        }
        else {
          // we need to increment the count for empty lanes too.
          count += 1;
        }
      });
    });
  };

  renderBattlecards = battlecards => {
    const {onToggleCuratorTools} = this.props;
    const items = battlecards.map(b => ({
      key: `bc_${b.id}`,
      itemTitle: b.title || '(Untitled Battlecard)',
      isDraft: b.isDraft,
      isNew: moment().diff(b.createdAt, 'days') <= 7,
      onClick: async () => {
        if(this._getIsCollapsed('tools')) {
          await onToggleCuratorTools();
        }

        this.handleLoadBattlecard(b);
      }
    }));

    return (
      <CardVitalsTocList
        items={items}
        title="Battlecard"
        isCurating={this.isCuratorInEditMode()}
        isOpen={this.state.battlecardsOpen}
        onItemClick={this.handleToggleBattlecards}
        onAddClick={this.handleAddBattlecardClick} />
    );
  };

  renderLanes = lanes => {
    const {lanesOpen, lanesLoading} = this.state;
    const isCurating = this.isCuratorInEditMode();
    const items = lanes.reduce((arr, l) => {
      // NOTE: show empty lanes to curators *only* in edit mode (they see the same thing as consumers in view mode)
      const isVisible = l.cards.length || isCurating;
      const isScratchpad = l.name.toLowerCase() === 'scratchpad';

      if(isVisible && !isScratchpad) {
        const {id, name, viewOrder, createdAt} = l;

        arr.push({
          id,
          key: `l_${id}`,
          itemTitle: name,
          // minus 1 because we need to ignore scratchpad
          index: (parseInt(viewOrder, 10) - 1) || 0,
          viewOrder,
          isNew: moment().diff(createdAt, 'days') <= 7,
          onClick: () => handleScrollToLane(l)
        });
      }

      return arr;
    }, []);

    return (
      <CardVitalsTocList
        items={items}
        title="Lane"
        icon="view_column"
        isCurating={isCurating}
        isOpen={lanesOpen}
        onItemClick={this.handleToggleLanes}
        onAddClick={this.handleAddLaneClick}
        onLanesReorder={this.handleLanesReorder}
        lanesLoading={lanesLoading}
        isRorderable={true} />
    );
  };

  render() {
    const {rival} = this.context.utils || {};
    const {lanes} = this.props;
    const battlecards = dig(rival, 'profile.battlecards', []);
    let tocRegion = null;

    if(!_.isEmpty(rival) && (lanes.length || battlecards.length)) {
      tocRegion = (
        <div className="vitals-toc">
          {this.renderBattlecards(battlecards)}
          {this.renderLanes(lanes)}
        </div>
      );
    }

    return tocRegion;
  }

}

export default withRouter(CardVitalsToc);

import ItemList from './_item_list';
import CompanySettingsAlertsReviews from './_company_settings_alerts_reviews';
import RadioCheckboxList from './_radio_checkbox_list';
import {AlertSubscriptionsProvider} from '../contexts/_alertSubscriptions';

import {preferenceTypes} from '../modules/constants/alerts';
import {
  alertsPreferenceUpdate,
  alertsPreferenceGet,
  alertsPreferenceDelete,
  alertsRssGet,
  alertsRssAdd,
  alertsRssUpdate,
  alertsRssDelete
} from '../modules/api/settings';
import AlertRuleComposer from './_alert_rule_composer/_alert_rule_composer.js';
import {Link} from 'react-router-dom';
import {
  getAlertPreferenceAlreadyExistMessage,
  getAlertPreferencesTypeConfirmationMessageBody,
  getAlertPreferencesTypeConfirmationButtonTitle,
  getAlertPreferencesTypeConfirmationMessageTitle
} from '../modules/company_settings_utils';
import {isValidUrl} from '../modules/url_utils';
import {connect} from 'react-redux';

class CompanySettingsAlerts extends React.Component {

  static contextTypes = {
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    rivals: PropTypes.object.isRequired,
    dispatch: PropTypes.any.isRequired
  };

  state = {
    configOptions: this.context.utils.company.companyData?.defaults?.autoPromote ?? {},
    enableTeamsApp: this.context.utils.company.companyData?.enableTeamsApp ?? false
  };

  handleToggleContentTypeOption = option => {
    this.setState(prevState => ({
      configOptions: {
        ...prevState.configOptions,
        [option]: !prevState.configOptions[option]
      }
    }), () => {
      const {configOptions} = this.state;
      const {utils: {featureFlagUpdate}} = this.context;
      const featureFlagPath = ['defaults', 'autoPromote'];
      const featureFlagValue = {...configOptions};

      featureFlagUpdate(featureFlagPath, featureFlagValue);
    });
  };

  alertsPreferenceUpdate = params => {
    const {utils} = this.context;
    const {dialog: {alert, confirmRestrictive}} = utils;
    const {type, value, currentItems, input} = params;
    const items = currentItems.map(item => item.value);

    if(!isValidUrl(`https://${value}`)) {
      alert('Whoops 😬 invalid domain, please enter a bare domain name.', () => input && input.focus());

      return Promise.reject({showError: false, focusInput: false});
    }

    if(items.includes(value.toLowerCase())) {
      alert(getAlertPreferenceAlreadyExistMessage(type), () => input && input.focus());

      return Promise.reject({showError: false, focusInput: false});
    }

    const dialogBodyContent = getAlertPreferencesTypeConfirmationMessageBody(value, type, false);

    return new Promise((resolve, reject) => {
      confirmRestrictive({
        message: getAlertPreferencesTypeConfirmationMessageTitle(type),
        buttonOk: getAlertPreferencesTypeConfirmationButtonTitle(type),
        bodyContent: dialogBodyContent,
        okCallback() {
          alertsPreferenceUpdate({type, value})
            .then(item => resolve(item))
            .catch(error => reject(error));
        },
        cancelCallback() {
          reject({showError: false});
        }
      });
    });
  };

  renderContentTypes = () => {
    const {configOptions, enableTeamsApp} = this.state;

    const selectedValue = [];

    for(const [key, value] of Object.entries(configOptions)) {
      if(value) {
        selectedValue.push(key);
      }
    }

    const reviewRoleOptions = [
      {value: 'email', label: 'E-mail'},
      {value: 'extension', label: 'Extension'},
      {value: 'slack', label: 'Slack'}
    ];

    if(enableTeamsApp) {
      reviewRoleOptions.push({value: 'teams', label: 'Teams'});
    }

    return (
      <RadioCheckboxList
        options={reviewRoleOptions}
        multiSelect={true}
        selectedValue={selectedValue}
        onOptionClick={option => this.handleToggleContentTypeOption(option)}
        keyPrefix="review-role" />
    );
  };

  render() {
    const {rivals, dispatch} = this.props;

    return (
      <div className="company-settings company-settings-review">
        <h3 className="u-m0">Alerts Settings</h3>
        <p className="help-block u-mb-m">Manage your <Link to="/alerts"><strong>Alerts</strong></Link> settings
          &mdash; blocklisted domains, favorited domains, RSS feeds.</p>

        <span>
          <h4 className="u-pt-s"><strong>Auto-Publish Alerts</strong></h4>
          <p className="help-block">When intel is sent from the selected sources below, it will be automatically published from Alerts into the Klue Feed.</p>
          {this.renderContentTypes()}
        </span>

        <h4 className="u-pt-s"><strong>Blocklisted Domains</strong></h4>
        <ItemList
          label="blocklist"
          onAdd={this.alertsPreferenceUpdate}
          onDelete={alertsPreferenceDelete}
          onLoad={alertsPreferenceGet}
          type={preferenceTypes.BLOCKLIST} />

        <h4 className="u-pt-s"><strong>Favorited Domains</strong></h4>
        <ItemList
          label="favorites list"
          onAdd={this.alertsPreferenceUpdate}
          onDelete={alertsPreferenceDelete}
          onLoad={alertsPreferenceGet}
          type={preferenceTypes.FAVORITE} />

        <h4 className="u-pt-s"><strong>RSS Feeds</strong></h4>
        <ItemList
          label="rss feeds list"
          onAdd={alertsRssAdd}
          onDelete={alertsRssDelete}
          onLoad={alertsRssGet}
          onUpdate={alertsRssUpdate}
          type={preferenceTypes.RSS}
          moreOptions={true} />
        <CompanySettingsAlertsReviews />
        <AlertSubscriptionsProvider rivals={rivals} dispatch={dispatch}>
          <AlertRuleComposer />
        </AlertSubscriptionsProvider>
      </div>
    );
  }

}

const mapDispatchToProps = dispatch => ({
  dispatch
});

const mapStateToProps = ({rivals}) => ({rivals: rivals.items});

export default connect(mapStateToProps, mapDispatchToProps)(CompanySettingsAlerts);

import Modal from '../_modal';
import EditorComponent from './_editor';
import ModalEditorFooter from './_modal_editor_footer';
import ModalEditorMessage from './_modal_editor_message';
import ModalOverlay from './_modal_overlay';
import ModalEditorPermissionManager from './_modal_editor_permission_manager';
import Resizer from './Resizer';
import {Direction} from './Resizer/constants';
import {createCardSources} from '../../modules/api/sources';

import {
  normalizeHtmlContent,
  getContentFromScratchpadComment,
  extractHtmlFormulasToArray,
  replaceFormulasByDomElements,
  getFormulaKey
} from '../../modules/editor_utils';
import {getPermissionGroupButtonTitle} from '../../modules/permission_utils';
import {
  CARD_TITLE_CLASSNAME,
  CARD_WRAPPER_CLASSNAME,
  notificationMessages,
  notificationTypes,
  editorStatusMessages,
  formulaKeyMappings
} from '../../modules/constants/editor';
import {cardCreate, cardGet, cardUpdate} from '../../modules/api/cards';
import {wait} from '../../modules/utils';
import {
  putCardToLS,
  deleteCardFromLS,
  getCardFromLS,
  getEditorBounds,
  setEditorBounds
} from '../../modules/local_storage_utils';
import {customEvents} from '../../modules/constants/ui';

import ReactUpdate from 'immutability-helper';
import className from 'classnames';
import CardTags from '../card_templates/_card_tags';
import {debounce} from 'lodash';

const initialState = {
  isLoading: true,
  isSaving: false,
  isFromScratchpad: false,
  isFromLocalStorage: false,
  isEditorBusy: false,
  cardData: {
    isDraft: true,
    allAccess: false,
    data: {
      name: '',
      textHtml: '',
      hasDynamicBlocks: false
    },
    viewOrder: 0
  },
  statusMessage: '',
  title: '',
  notificationMessage: '',
  notificationStatus: '',
  isEditorActive: false,
  formulas: [],
  isPermissionsManagerVisible: false,
  isDraftFromPermissions: null,
  allAccessFromPermissions: null,
  visibilityGroupsFromPermissions: null,
  scratchpadItem: {},
  tagsToUpdate: null,
  editorToolbarEnabled: true,
  isFullScreen: false
};

class CardEditModal extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      ...initialState
    };
  }

  static contextTypes = {
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    onUpdate: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    authorId: PropTypes.number,
    cardId: PropTypes.number,
    boardId: PropTypes.number,
    profileId: PropTypes.number.isRequired,
    location: PropTypes.object,
    wideMode: PropTypes.bool,
    visible: PropTypes.bool,
    // eslint-disable-next-line react/no-unused-prop-types
    showCardPermissions: PropTypes.bool
  };

  static defaultProps = {
    onUpdate() {},
    onCreate() {},
    onClose() {},
    authorId: null,
    cardId: null,
    boardId: null,
    profileId: null,
    location: {},
    wideMode: false,
    visible: false,
    showCardPermissions: false
  };

  componentDidMount() {
    console.log('CardEditModal.componentDidMount: props: %o', this.props);

    this.titleInput && this.titleInput.focus();

    if(this.props.visible) {
      this.setupCardContent();
      this.setModalPosition();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(!prevProps.visible && this.props.visible) {
      this.setupCardContent();
      this.setModalPosition();
    }

    // if card is closing then reset the state
    if(prevProps.visible && !this.props.visible && prevState) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(initialState);
    }
  }

  setModalPosition = () => {
    const panel = this.containerRef;

    if(!panel) {
      return;
    }

    const {width = '725px', height = '500px'} = getEditorBounds();

    panel.style.width = width;
    panel.style.left = 0;
    panel.style.height = height;
    panel.style.top = 0;
  };

  setupCardContent = () => {
    this.getInitialCardData()
      .then((state = {}) => {
        this.setState({
          isLoading: false,
          ...state
        });
      })
      .catch(error => {
        this.setState({isLoading: false});
        console.error('CardModal:setupCardContent error: %o', error);
      });
  };

  /**
   * This function is responsible for handling the initial data logic
   * called on componentDidMount, the different conditions are:
   * - Creating a new card from scratch will only check local storage for backups
   * - Creating a new card from a comment will fill the editor with the comment body
   * - Editing a current card will check for local storage backup
   * - Adding a scratchpad comment to an existent card will put the comment body
   * after the current text and add a new source
   *
   * @memberof CardEditModal
   * @returns {Promise} an state object with initial values
   */
  getInitialCardData = () => {
    const {cardId, location: {state: locationState = {}}} = this.props;
    const {comment: locComment, showCardPermissions = false, previewingId = null} = locationState;
    const recoveredContent = this.getRecoveredCardContent();
    const hasLocalStorageContent = Boolean(recoveredContent.text || recoveredContent.title);
    const isFromScratchpad = Boolean(locComment && locComment.body);

    return new Promise((resolve, reject) => {
      // editing an existing card
      if(cardId) {
        return cardGet({cardOptions: {cardId}})
          .then(card => {
            let cardHtml = (card.data.textHtml || '').trim();
            let hasDynamicBlocks;

            if(isFromScratchpad) {
              // adding a comment from the scratchpad to an existing card
              const comment = getContentFromScratchpadComment(locComment);

              cardHtml = `${cardHtml}<hr />${comment}`.trim();

              this.setState({
                scratchpadItem: locComment
              });
            }

            if(hasLocalStorageContent) {
              cardHtml = recoveredContent.text.trim();
            }

            // replace any formulas in existing card content
            if(cardHtml) {
              const formulas = extractHtmlFormulasToArray(cardHtml);

              this.setState({formulas, isPermissionsManagerVisible: showCardPermissions});

              hasDynamicBlocks = Boolean(formulas.length);
              cardHtml = replaceFormulasByDomElements(cardHtml, formulas, true);
            }
            else {
              this.setState({isPermissionsManagerVisible: showCardPermissions});
            }

            return resolve({
              isFromScratchpad,
              isFromLocalStorage: hasLocalStorageContent,
              notificationMessage: hasLocalStorageContent ? notificationMessages.LOCAL_COPY : '',
              notificationStatus: notificationTypes.WARNING,
              cardData: {
                ...card,
                data: {
                  ...card.data,
                  name: recoveredContent.title || card.data.name || '',
                  textHtml: cardHtml,
                  hasDynamicBlocks
                }
              }
            });
          })
          .catch(error => {
            console.error('CardModal: error: %o', error);

            return reject(error);
          });
      }

      // creating a new card from localStorage
      if(hasLocalStorageContent) {
        const {title, text} = recoveredContent;
        const formulas = extractHtmlFormulasToArray(text);
        const textHtml = replaceFormulasByDomElements(text, formulas, true) || '';

        this.setState({formulas});

        return resolve({
          isFromLocalStorage: true,
          notificationMessage: notificationMessages.LOCAL_COPY,
          notificationStatus: notificationTypes.WARNING,
          cardData: {
            data: {
              name: title || '',
              textHtml,
              hasDynamicBlocks: Boolean(formulas.length)
            }
          }
        });
      }

      // creating a new card from the scratchpad
      if(isFromScratchpad) {
        this.setState({
          scratchpadItem: locComment
        });

        return resolve({
          isFromScratchpad: true,
          cardData: {
            data: {
              name: '',
              textHtml: getContentFromScratchpadComment(locComment),
              hasDynamicBlocks: false
            }
          }
        });
      }

      const {utils: {visibilityGroups} = {}} = this.context;

      // 0 is a valid id... for full access groups hence this check and the (previewingId !== 0) below.
      if(previewingId !== null) {
        const visibilityGroupsFromPermissions = [];

        if(previewingId !== 0) {
          const group = (visibilityGroups || []).find(g => g.id === previewingId);

          if(group) {
            visibilityGroupsFromPermissions.push(group);
          }
        }

        this.setState({
          isDraftFromPermissions: true,
          allAccessFromPermissions: false,
          visibilityGroupsFromPermissions
        });
      }
      else {
        // see if there's a default card permission set and use that to initialize the modal_editor_permissions_manager
        const visibilityGroupsFromPermissions = (visibilityGroups || []).reduce((acc, group) => {
          if(group.isCardDefault) {
            acc.push(group);
          }

          return acc;
        }, []);

        if(visibilityGroupsFromPermissions.length) {
          this.setState({
            isDraftFromPermissions: true,
            allAccessFromPermissions: false,
            visibilityGroupsFromPermissions
          });
        }
      }

      resolve();
    });
  };

  /**
   * Verify if there's a local copy of the card content
   * and fill the current state with recovery data
   *
   * @memberof CardEditModal
   * @returns {object} local storage object
   */
  getRecoveredCardContent = () => {
    const {boardId: parentId, cardId: id} = this.props;
    const recoveredCard = getCardFromLS({id, parentId}) || {};

    return recoveredCard;
  };

  handleFormulaInsert = (formulaIndex = 0, formula = '') => {
    const {formulas} = this.state;
    const insertedFormula = formula.trim();

    return new Promise((resolve, reject) => {
      if(!insertedFormula) {
        return reject('Formula not specified');
      }

      this.setState({
        formulas: ReactUpdate(formulas, {$splice: [[formulaIndex, 0, insertedFormula]]})
      }, () => resolve(this.state.formulas));
    });
  };

  handleFormulaUpdate = (formulaIndex = 0, formula = '') => {
    const {formulas} = this.state;
    const updatedFormula = formula.trim();

    return new Promise((resolve, reject) => {
      if(!updatedFormula) {
        return reject('Formula not specified');
      }

      formulas[formulaIndex] = updatedFormula;

      this.setState({formulas}, () => resolve(this.state.formulas));
    });
  };

  handleFormulaDelete = (formulaIndex = 0) => {
    const {formulas} = this.state;

    return new Promise(resolve => {
      formulas.splice(formulaIndex, 1);

      this.setState({formulas}, () => resolve());
    });
  };

  handleSave = () => {
    const {utils: {company} = {}} = this.context;
    const {editor} = this.editorRef;
    const {formulas, cardData: currentCardData, tagsToUpdate} = this.state;
    const {data: {name = ''}} = currentCardData;
    const {isDraft, allAccess, visibilityGroups} = this.activeVisibilityGroupSettings();

    // NOTE: the primary use case will be that titles *are* required for all cards; this feature flag
    // is to disable that requirement only for specific customer ins ances post-migration (see #5123)
    const {companyData: {cardTitlesOptional = false} = {}} = company || {};

    const {
      authorId,
      cardId,
      boardId,
      onCreate,
      onUpdate,
      location: {state: routeState = {}}
    } = this.props;
    const {comment = null, useViewOrder = 0} = routeState;
    const isNewCard = !cardId;

    editor.html.cleanEmptyTags();

    let editorHtml = editor.html.get();
    let createCardSourcesError = false;

    if(!(name || '').trim() && !cardTitlesOptional) {
      return this.setState({
        isSaving: false,
        notificationMessage: notificationMessages.FORM_INVALID,
        notificationStatus: notificationTypes.WARNING
      }, () => {
        if(this.titleInput) {
          if(formulas.length) {
            const labels = [];

            formulas.forEach(formula => {
              const {label = ''} = formulaKeyMappings[getFormulaKey(formula)] || {};

              labels.push(label);
            });

            const label = labels.join(', ');

            this.titleInput.value = label;
            this.titleInput.select();
            this.titleInput.addEventListener('blur', event => {
              const {target: {value = ''}} = event;

              this.setState({
                cardData: {
                  ...currentCardData,
                  ...{
                    data: {
                      ...currentCardData.data, name: value
                    }
                  }
                }
              });
            }, {once: true});
            this.titleInput.addEventListener('change', () => this.setState({notificationMessage: ''}), {once: true});
          }
          else {
            this.titleInput.focus();
          }
        }
      });
    }

    // NOTE: can't wrap this in a formulas.length check because there could be open dynamic block editors in a new card
    const {textHtml, matchedFormulas} = normalizeHtmlContent(editorHtml, formulas);

    editorHtml = textHtml;

    const draftState = (isDraft !== null) ? isDraft : true;

    const newCardData = {
      author: authorId,
      reviewerId: authorId,
      boardId,
      isDraft: draftState,
      allAccess: (allAccess !== null) ? allAccess : false,
      templateName: 'CardHTML',
      data: {
        ...currentCardData.data,
        name: name || '',
        textHtml: editorHtml,
        hasDynamicBlocks: Boolean(matchedFormulas.length)
      }
    };

    if(tagsToUpdate) {
      newCardData.tags = tagsToUpdate;
    }

    if(useViewOrder) {
      newCardData.viewOrder = useViewOrder;
    }

    if(visibilityGroups) {
      newCardData.visibilityGroups = visibilityGroups.map(group => group.id);
    }

    const saveData = isNewCard ? newCardData : {id: cardId, data: newCardData};

    // delete temporary sources from the cardData
    const hasSourcesInCardData = !_.isEmpty(saveData.data?.data?.sources);

    hasSourcesInCardData && delete saveData.data?.data?.sources;

    this.setState({
      isSaving: true,
      statusMessage: 'Saving...'
    }, () => {
      (isNewCard ? cardCreate : cardUpdate)(saveData)
        .then(async ({data: cardData}) => {
          if(_.isEmpty(cardData)) {
            return;
          }

          // remove any existing copy from local storage
          this.deleteLocalCopy();

          const {textHtml: updatedHtml} = cardData.data;
          const extractedFormulas = extractHtmlFormulasToArray(updatedHtml);

          document.dispatchEvent(new CustomEvent(
            customEvents.onEditCard,
            {detail: {card: cardData}}
          ));

          // create card source
          const {scratchpadItem} = this.state;

          if(!_.isEmpty(scratchpadItem)) {
            const sourceOptions = {
              cardId: cardData.id,
              commentId: scratchpadItem.id
            };

            const sourceResponse = await createCardSources({sourceOptions});
            // Chicken or Egg? I have to save the card and get the id for new cards before I can update
            // the DnD source so I update the sources count here directly instead of re-reading the card again.

            if(sourceResponse) {
              cardData.sourcesCount += 1;
            }
            else {
              createCardSourcesError = true;
            }
          }

          this.setState({
            formulas: extractedFormulas,
            isSaving: false,
            isFromLocalStorage: false,
            notificationMessage: '',
            cardData: {...cardData,
              data: {
                ...cardData.data,
                hasDynamicBlocks: Boolean(extractedFormulas.length),
                textHtml: replaceFormulasByDomElements(updatedHtml, extractedFormulas, true)
              }
            },
            statusMessage: `Card ${isNewCard ? 'created' : 'updated'}!`
          }, () => {
            (isNewCard
              ? onCreate
              : onUpdate)(cardData, createCardSourcesError ? null : comment); // if there was an error on createCardSources, don't pass the comment for deletion
            wait(3000).then(() => this.setState({statusMessage: ''}));
          });
        })
        .catch(error => {
          console.error('CardModal: error saving: %o', error);

          this.setState({isSaving: false});
        });
    });
  };

  handleTitleChange = event => {
    if(event) {
      event.preventDefault();
    }

    const {value} = event.target;

    this.setState(prevState => ({
      cardData: ReactUpdate(prevState.cardData, {
        data: {
          name: {$set: value}
        }
      })
    }), this.handleSaveContentToLocal);
  };

  handleTitleKeyPress = event => {
    if(event.key === 'Enter') {
      this.editorRef.editor.events.focus();
    }
  };

  /**
   * Save a copy of the content on local storage
   *
   * @memberof CardEditModal
   */
  handleSaveContentToLocal = () => {
    const {formulas, cardData: {data: {name: val2}}, isFromLocalStorage, notificationMessage} = this.state;
    const {boardId: parentId, cardId: id} = this.props;
    const {textHtml: val1} = normalizeHtmlContent(this.editorRef.editor.html.get(), formulas);

    if(isFromLocalStorage || notificationMessage) {
      this.setState({notificationMessage: '', isFromLocalStorage: false});
    }

    putCardToLS({val1, val2, template: '', id, parentId});
  };

  handleHtmlSet = () => this.state.isFromScratchpad && this.handleSaveContentToLocal();

  /**
   * Delete the local copy of card content
   *
   * @memberof CardEditModal
   */
  deleteLocalCopy = () => {
    const {boardId: parentId, cardId: id} = this.props;

    deleteCardFromLS({id, parentId});
  };

  handleModalClose = () => {
    this.deleteLocalCopy();

    this.props.onClose();
  };

  handleEditorBusyChange = busy => this.setState({
    isEditorBusy: busy,
    statusMessage: busy ? editorStatusMessages.UPLOADING : ''
  });

  handlePermissionsClick = event => {
    event.preventDefault();

    this.setState({isPermissionsManagerVisible: true});
  };

  handlePermissionsClose = ({isDraftFromPermissions, allAccessFromPermissions, visibilityGroupsFromPermissions}) => {
    this.setState({
      isDraftFromPermissions,
      allAccessFromPermissions,
      visibilityGroupsFromPermissions,
      isPermissionsManagerVisible: false
    });
  };

  createEditorRef = ref => this.editorRef = ref;

  createTitleInputRef = ref => this.titleInput = ref;

  activeVisibilityGroupSettings = () => {
    const {
      cardData: {
        visibilityGroups,
        isDraft,
        allAccess
      },
      isDraftFromPermissions,
      allAccessFromPermissions,
      visibilityGroupsFromPermissions
    } = this.state;

    return {
      isDraft: (isDraftFromPermissions !== null) ? isDraftFromPermissions : isDraft,
      allAccess: (allAccessFromPermissions !== null) ? allAccessFromPermissions : allAccess,
      visibilityGroups: (visibilityGroupsFromPermissions !== null) ? visibilityGroupsFromPermissions : visibilityGroups
    };
  };

  handleTagsUpdated = tags => this.setState({tagsToUpdate: tags.map(t => t.id)});

  setContainerRef = el => {
    this.containerRef = el;
  };

  saveModalPositionToLocalStorage = debounce(position => setEditorBounds(position), 500);

  handleResize = (direction, movementX, movementY) => {
    const panel = this.containerRef;

    if(!panel) {
      return;
    }

    const {width, height} = panel.getBoundingClientRect();
    const newWidth = `${Math.floor(width + movementX * 2)}px`;
    const newHeight = `${Math.floor(height + movementY * 2)}px`;

    switch(direction) {
      case Direction.BottomRight:
        panel.style.width = newWidth;
        panel.style.height = newHeight;
        break;
      default:
        break;
    }

    this.saveModalPositionToLocalStorage({
      width: newWidth,
      height: newHeight});
  };

  handleToolbarEnabled = editorToolbarEnabled => this.setState({editorToolbarEnabled});

  handleToggleFullScreen = () => {
    this.setState(prevState => ({
      isFullScreen: !prevState.isFullScreen
    }));
  };

  render() {
    const {wideMode, cardId, profileId, visible, location: {state: locationState = {}}} = this.props;
    const {previewingId = null} = locationState;
    const {
      cardData,
      formulas,
      isLoading,
      isSaving,
      statusMessage,
      notificationStatus,
      notificationMessage,
      isEditorBusy,
      isPermissionsManagerVisible,
      editorToolbarEnabled
    } = this.state;
    const {
      data: {name: cardTitle, textHtml: cardContent}
    } = cardData;

    if(!visible) {
      return null;
    }

    // editor options API overrides
    const editorConfig = {
      toolbarContainer: '.card-editor-toolbar',
      autofocus: false,
      placeholderText: 'Card Contents',
      events: {
        contentChanged: this.handleSaveContentToLocal
      }
    };
    const {isDraft, allAccess, visibilityGroups} = this.activeVisibilityGroupSettings();
    const {title: permissionsTitle, count, tooltip} = !isLoading
      ? getPermissionGroupButtonTitle({isDraft, allAccess, visibilityGroups})
      : {};
    const modalPermissionsVisible = isPermissionsManagerVisible;
    const {isFullScreen} = this.state;

    return (

      <Modal header={false} hideCloseButton={true} padded={false} basic={true} showResize={true} isFullScreen={isFullScreen}>
        <ModalOverlay visible={modalPermissionsVisible} />
        <ModalEditorPermissionManager
          cardId={cardId}
          assignedVisibilityGroups={visibilityGroups}
          visible={modalPermissionsVisible}
          isDraftCard={isDraft}
          eventTypes={['mousedown', 'touchstart', 'keydown']}
          isEveryoneCard={allAccess}
          previewingId={previewingId}
          onClose={this.handlePermissionsClose} />
        <div ref={this.setContainerRef} className={className('card-editor modal-editor', {'modal-editor--wider': wideMode}, {'full-screen': isFullScreen})}>
          <Resizer onResize={this.handleResize} />
          <div className={className('card-editor-toolbar', {disabled: !editorToolbarEnabled})} />
          <ModalEditorMessage type={notificationStatus} visible={Boolean(notificationMessage)} content={notificationMessage} />
          <div className={className(`modal-editor-card-container ${CARD_WRAPPER_CLASSNAME}`, {'modal-editor-card-container--wider': wideMode,
            'modal-editor-card-container-full-screen': isFullScreen})}>
            <div className={className('modal-editor-card-container_inner', {'modal-editor-card-container_inner-full-screen': isFullScreen})}>
              <div className={`card-editor-title ${CARD_TITLE_CLASSNAME}`}>
                <input
                  ref={this.createTitleInputRef}
                  autoComplete="title"
                  className="card-editor-title_input"
                  onChange={this.handleTitleChange}
                  onKeyPress={this.handleTitleKeyPress}
                  value={cardTitle || ''}
                  placeholder={isLoading ? '' : 'Card Title'} />
              </div>
              {!isLoading && (
                <>
                  <EditorComponent
                    editorRef={this.createEditorRef}
                    editorConfig={editorConfig}
                    initialValue={cardContent || ''}
                    profileId={profileId}
                    onBusy={this.handleEditorBusyChange}
                    onHtmlSet={this.handleHtmlSet}
                    onImageUploadSuccess={this.handleSaveContentToLocal}
                    formulas={formulas}
                    onFormulaInsert={this.handleFormulaInsert}
                    onFormulaUpdate={this.handleFormulaUpdate}
                    onFormulaDelete={this.handleFormulaDelete}
                    onToolbarEnabled={this.handleToolbarEnabled}
                    handleToggleFullScreen={this.handleToggleFullScreen}
                    titleRef={this.titleInput} />
                  <CardTags
                    card={cardData}
                    disableTags={true}
                    editable={true}
                    onTagsUpdated={this.handleTagsUpdated} />
                </>
              )}
            </div>
          </div>
          <ModalEditorFooter
            isNewCard={!cardId}
            onCancelClick={this.handleModalClose}
            onPermissionsClick={this.handlePermissionsClick}
            statusMessage={statusMessage}
            permissionTitle={permissionsTitle}
            permissionCount={count}
            tooltip={tooltip}
            onSaveClick={this.handleSave}
            saving={isSaving}
            isFullScreen={isFullScreen}
            loading={isLoading || isSaving || isEditorBusy} />
        </div>
      </Modal>
    );
  }

}

export default CardEditModal;
